import React from 'react';
import { KeyboardShortcutSpec } from '../../../../keyboardSupport/lib';
import ModalWithSections, {
  RenderSectionComponent,
} from '../../../components/Modal/ModalWithSections';
import ThemePreferences from './ThemePreferences';
import PowerBiPreferences from './PowerBiPreferences';
import usePreferenceSections, { PreferencesSectionId } from './usePreferenceSections';

const RenderPreferencesSection: RenderSectionComponent<PreferencesSectionId> = ({ id }) => {
  if (id === 'theme') {
    return <ThemePreferences />;
  } else if (id === 'powerBi') {
    return <PowerBiPreferences />;
  }

  return null;
};

export const UserPreferencesShortcutSpec: KeyboardShortcutSpec = {
  keyCombo: { code: 'Comma', shift: true, meta: true },
  description: 'Open preferences',
};

type UserPreferencesEditorProps = {
  isActive?: boolean;
  onClose: () => void;
  initialSectionId?: PreferencesSectionId;
};

const UserPreferencesEditor: React.FC<UserPreferencesEditorProps> = ({
  isActive,
  onClose,
  initialSectionId,
}) => {
  const sections = usePreferenceSections();
  return (
    isActive && (
      <ModalWithSections<PreferencesSectionId>
        title="Settings"
        onCloseClick={onClose}
        RenderSection={RenderPreferencesSection}
        sections={sections}
        selectedSectionId={initialSectionId}
      />
    )
  );
};

export default UserPreferencesEditor;
