import React, { useContext } from 'react';
import { FCC } from '../../../../utils/types';
import { useAuthenticationContext } from '../../common/authentication/AuthenticationContext';
import { withQueryParams } from '../../../../utils/querystringUtils';
import { path } from '../../crisp/routing/lib';

export type RouteSwitchContextInterface = {
  useIsSignedIn: () => { isSignedIn: boolean; loading: boolean };
  getSignInPath: (returnTo: string) => string;
};

export const RouteSwitchContextDefaults = {
  useIsSignedIn: useAuthenticationContext,
  getSignInPath: (returnTo: string) => withQueryParams({ returnTo })(path('SignIn')()),
};

export const RouteSwitchContext = React.createContext<RouteSwitchContextInterface>(
  RouteSwitchContextDefaults,
);

const RouteSwitchProvider: FCC<RouteSwitchContextInterface> = ({ children, ...props }) => (
  <RouteSwitchContext.Provider value={props}>{children}</RouteSwitchContext.Provider>
);

export default RouteSwitchProvider;

export const useRouteSwitchContext = () => useContext(RouteSwitchContext);
