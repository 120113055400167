import React, { ReactNode, Suspense, useLayoutEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router';
import MaxWidthLayout from '../../components/layout/MaxWidthLayout';
import { useHelpUrl } from '../../crisp/navigation/HelpLinkContextProvider';
import NavRailPageTemplate from '../../components/NavRailPageTemplate';
import NavRailMenu from '../navigation/NavRailMenu';
import { PartnerAccountProps, PartnerApplicationParams } from '../lib';
import PartnerContextProvider from '../lib/PartnerContext';
import { Theme } from '../../components/Theme';
import { useUserPreferencesContext } from '../../crisp/UserPreferences/UserPreferencesProvider';
import { useThemeContext } from '../../components/Theme/ThemeProvider';
import { partnerAccount } from '../../components/lib/Account';
import SegmentPartnerAccountTracker from './SegmentPartnerAccountTracker';

const usePartnerAccountTheme = () => {
  const history = useHistory();
  // Retrieve partner theme based on partner account settings (pass partnerId as param to this hook)
  const { preferences } = useUserPreferencesContext();
  const overrideTheme = preferences.general.overrideAccountTheme;
  const { setTheme: applyTheme } = useThemeContext();

  useLayoutEffect(() => {
    if (!overrideTheme) {
      applyTheme(Theme.Dot);
      const onChange = () => applyTheme(Theme.Dot);
      return history.listen(onChange);
    }
  }, [history, overrideTheme, applyTheme]);
};

type PartnerTemplateProps<T extends object> = {
  children?: ReactNode;
  helpUrl?: string;
  Layout?: 'div' | React.FC<T>;
};

function PartnerTemplate<T extends object>({
  children,
  Layout = MaxWidthLayout as React.FC<T>,
  helpUrl,
  ...props
}: PartnerTemplateProps<T>) {
  const { params } = useRouteMatch<PartnerApplicationParams>();
  const { partnerApplication } = params;
  const account = useMemo(() => partnerAccount(partnerApplication), [partnerApplication]);

  useHelpUrl(helpUrl);
  usePartnerAccountTheme();

  const layoutProps = props as T;
  return (
    <NavRailPageTemplate<PartnerAccountProps> RenderNavRail={NavRailMenu} partnerAccount={account}>
      <main id="main">
        <PartnerContextProvider partnerAccount={account}>
          <Layout {...layoutProps}>
            <Suspense>{children}</Suspense>
          </Layout>
        </PartnerContextProvider>
      </main>
      <SegmentPartnerAccountTracker partnerApplication={partnerApplication} />
    </NavRailPageTemplate>
  );
}

export default PartnerTemplate;
