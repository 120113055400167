import React from 'react';
import {
  CommonFieldProps,
  FormElement,
  PartialFormElementProps,
} from '../../../ui/form/FormFields';
import CheckboxField, { CheckboxFieldProps } from '../CheckboxField';

type CheckboxFormFieldProps = PartialFormElementProps<boolean> &
  Pick<CheckboxFieldProps, Exclude<CommonFieldProps, 'type' | 'isFocused'>>;

const CheckboxFormField: React.FC<CheckboxFormFieldProps> = ({
  id,
  validate,
  name,
  defaultValue,
  ...checkboxFieldProps
}) => {
  return (
    <FormElement
      id={id}
      name={name}
      defaultValue={defaultValue}
      validate={validate}
      render={({ triggerValidation: _, onChange, errorMessage, value, ...renderProps }) => (
        <CheckboxField
          {...renderProps}
          {...checkboxFieldProps}
          error={errorMessage}
          onChange={onChange}
          checked={value}
        />
      )}
    />
  );
};

export default CheckboxFormField;
