import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import noop from 'lodash/noop';
import { useAuthenticationContext } from '../lettuce/common/authentication/AuthenticationContext';
import { FCC } from '../../utils/types';
import { initWidget, ZendeskWidgetInstance, ZendeskWidgetOptions } from './lib';

const { REACT_APP_HELP_URL } = process.env;

export type ZendeskWidgetContextInterface = {
  setSuggestions: (suggestions?: string) => void;
  update: (key: string, options?: ZendeskWidgetOptions) => void;
  updateOptions: (options: ZendeskWidgetOptions) => void;
  hide: () => void;
  show: () => void;
  helpLink: Maybe<string>;
};

export const ZendeskWidgetContext = React.createContext<ZendeskWidgetContextInterface>({
  setSuggestions: noop,
  update: noop,
  updateOptions: noop,
  hide: noop,
  show: noop,
  helpLink: undefined,
});

// Trigger the expanded panel which also contains the "Contact us" button
const emptySuggestions = '_';

const getHelpLink = (suggestions: Maybe<string>) => {
  if (!REACT_APP_HELP_URL) {
    return undefined;
  }

  if (suggestions && suggestions !== emptySuggestions) {
    return `${REACT_APP_HELP_URL}/hc/en-us/search?query=${encodeURIComponent(suggestions)}`;
  }

  return REACT_APP_HELP_URL;
};

export const ZendeskWidgetContextProvider: FCC = ({ children }) => {
  const [suggestions, setSuggestions] = useState<Maybe<string>>();
  const zendeskWidgetInstanceRef = useRef<Maybe<ZendeskWidgetInstance>>();
  const { firebaseUser } = useAuthenticationContext();

  const updateSuggestions = useCallback(
    (nextSuggestions: Maybe<string>) => {
      const zendeskWidgetInstance = zendeskWidgetInstanceRef.current;
      setSuggestions(nextSuggestions);
      return zendeskWidgetInstance?.setSuggestions(nextSuggestions ?? emptySuggestions);
    },
    [setSuggestions],
  );

  const fns: ZendeskWidgetContextInterface = useMemo(() => {
    return {
      update: (key, options) => {
        const zendeskWidgetInstance = zendeskWidgetInstanceRef.current;
        if (zendeskWidgetInstance) {
          if (key !== zendeskWidgetInstance.key) {
            zendeskWidgetInstance.dispose();
            zendeskWidgetInstanceRef.current = undefined;
          } else {
            if (options) {
              zendeskWidgetInstance.update(options);
            }
            return;
          }
        }

        const nextInstance = initWidget(key, options);
        if (firebaseUser) {
          nextInstance.identify(firebaseUser);
        }
        zendeskWidgetInstanceRef.current = nextInstance;
      },
      updateOptions: options => zendeskWidgetInstanceRef.current?.update(options),
      setSuggestions: updateSuggestions,
      hide: zendeskWidgetInstanceRef.current?.hide ?? noop,
      show: zendeskWidgetInstanceRef.current?.show ?? noop,
      helpLink: getHelpLink(suggestions),
    };
  }, [firebaseUser, suggestions, updateSuggestions]);

  useEffect(() => {
    if (firebaseUser) {
      zendeskWidgetInstanceRef.current?.identify(firebaseUser);
    }
  }, [firebaseUser]);

  useEffect(() => () => zendeskWidgetInstanceRef.current?.dispose(), []);

  return <ZendeskWidgetContext.Provider value={fns}>{children}</ZendeskWidgetContext.Provider>;
};

export const useZendeskWidgetContext = () => useContext(ZendeskWidgetContext);
