import React, { useContext, useMemo } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { ApolloError } from '@apollo/client/errors';
import { ApolloQueryResult } from '@apollo/client';
import { Maybe } from '@tellurian/ts-utils';
import {
  GetCurrentUserQuery,
  useGetCurrentUserQuery,
  UserAccountPermission,
  UserAccountRole,
} from '../../generated/graphql';
import { LocalStorageKey } from '../../utils/localStorage';
import useLocalStorageState from '../../utils/localStorage/useLocalStorageState';
import { useAuthenticationContext } from '../lettuce/common/authentication/AuthenticationContext';

export interface AuthorizationContextInterface extends Partial<GetCurrentUserQuery> {
  firebaseUser: Maybe<firebase.User>;
  isSignedIn: boolean;
  getHasAccountAdminPermission: (accountId: string) => boolean;
  getHasAccountWritePermission: (accountId: string) => boolean;
  getHasAccountReadPermission: (accountId: string) => boolean;
  getHasAccountAccessPermission: (accountId: string) => boolean;
  getHasAccountPermission: (accountId: string, grant: AuthorityGrant) => boolean;
  getHasGlobalAdminPermission: () => boolean;
  getHasGlobalWritePermission: () => boolean;
  getHasGlobalReadPermission: () => boolean;
  getHasGlobalPermission: (grant: AuthorityGrant) => boolean;
  refresh: () => Promise<ApolloQueryResult<GetCurrentUserQuery> | void>;
  loading: boolean;
  error: ApolloError | undefined;
  isGlobalAdminPermissionDisabled: boolean;
  setIsGlobalAdminPermissionDisabled: (disabled: boolean) => void;
}

export const AuthorizationContext = React.createContext<AuthorizationContextInterface>({
  currentUser: undefined,
  firebaseUser: undefined,
  isSignedIn: false,
  getHasAccountAdminPermission: () => false,
  getHasAccountWritePermission: () => false,
  getHasAccountReadPermission: () => false,
  getHasAccountAccessPermission: () => false,
  getHasAccountPermission: () => false,
  getHasGlobalAdminPermission: () => false,
  getHasGlobalWritePermission: () => false,
  getHasGlobalReadPermission: () => false,
  getHasGlobalPermission: () => false,
  refresh: () => Promise.resolve(),
  loading: true,
  error: undefined,
  isGlobalAdminPermissionDisabled: false,
  setIsGlobalAdminPermissionDisabled: () => false,
});

export interface AuthorityScope {
  /**
   * Check if this scope covers the target scope.
   */
  covers: (targetScope: AuthorityScope) => boolean;
}

function isAccountScope(scope: AuthorityScope): scope is AccountAuthorityScope {
  return (scope as AccountAuthorityScope).accountId !== undefined;
}

export class AccountAuthorityScope implements AuthorityScope {
  accountId: string;
  covers = (targetScope: AuthorityScope) => {
    return isAccountScope(targetScope) && targetScope.accountId === this.accountId;
  };

  constructor(accountId: string) {
    this.accountId = accountId;
  }
}

export const GlobalAuthorityScope = Object.freeze({
  covers: () => true,
});

export interface AuthorityGrant {
  /**
   * Check if this grant either _is_ or implicitly includes the target grant.
   */
  includes: (targetGrant: AuthorityGrant) => boolean;
}

export const MemberAuthorityGrant: AuthorityGrant = Object.freeze({
  includes: (targetGrant: AuthorityGrant) => {
    return targetGrant === MemberAuthorityGrant;
  },
});

export const ViewerAuthorityGrant: AuthorityGrant = Object.freeze({
  includes: (targetGrant: AuthorityGrant) => {
    return targetGrant === ViewerAuthorityGrant || targetGrant === MemberAuthorityGrant;
  },
});

export const EditorAuthorityGrant: AuthorityGrant = Object.freeze({
  includes: (targetGrant: AuthorityGrant) => {
    return (
      targetGrant === EditorAuthorityGrant ||
      targetGrant === ViewerAuthorityGrant ||
      targetGrant === MemberAuthorityGrant
    );
  },
});

export const AdminAuthorityGrant = Object.freeze({
  includes: () => true,
});

class ScopedAuthorityGrant {
  scope: AuthorityScope;
  grant: AuthorityGrant;

  constructor(scope: AuthorityScope, grant: AuthorityGrant) {
    this.scope = scope;
    this.grant = grant;
  }

  isAuthorized(requestedAuthority: ScopedAuthorityGrant): boolean {
    return (
      this.scope.covers(requestedAuthority.scope) && this.grant.includes(requestedAuthority.grant)
    );
  }
}

export type UserInfo = {
  roles?: string[] | null;
  accountPermissions: Array<Pick<UserAccountPermission, 'accountId' | 'accountRole'>>;
};

export const authorizationChecker = (
  user: Maybe<UserInfo>,
  isGlobalAdminPermissionDisabled: boolean,
) => {
  const isSignedIn = !!user;

  const SCOPE_GLOBAL = 'ROLE';
  const SCOPE_ACCOUNT_PREFIX = 'ACCOUNT';
  const GRANT_ADMIN = 'ADMIN';
  const GRANT_EDITOR = 'EDITOR';
  const GRANT_VIEWER = 'VIEWER';
  const GRANT_MEMBER = 'MEMBER';

  const parseAuthorityScope = (scope: string) => {
    if (scope === SCOPE_GLOBAL) return GlobalAuthorityScope;
    if (scope.startsWith(SCOPE_ACCOUNT_PREFIX))
      return new AccountAuthorityScope(scope.substring(SCOPE_ACCOUNT_PREFIX.length));
    return null;
  };

  const parseAuthorityGrant = (grant: string) => {
    if (grant === GRANT_ADMIN) return AdminAuthorityGrant;
    if (grant === GRANT_EDITOR) return EditorAuthorityGrant;
    if (grant === GRANT_VIEWER) return ViewerAuthorityGrant;
    if (grant === GRANT_MEMBER) return MemberAuthorityGrant;
    return null;
  };

  const parseScopedAuthorityGrant = (role: string): ScopedAuthorityGrant | null => {
    const parts = role.split('_');
    if (parts.length !== 2) return null;

    const scope = parseAuthorityScope(parts[0]);
    const grant = parseAuthorityGrant(parts[1]);
    return scope !== null && grant !== null ? new ScopedAuthorityGrant(scope, grant) : null;
  };

  const toAuthorityGrant = (accountRole: UserAccountRole) => {
    if (accountRole === UserAccountRole.Admin) return AdminAuthorityGrant;
    if (accountRole === UserAccountRole.Editor) return EditorAuthorityGrant;
    if (accountRole === UserAccountRole.Viewer) return ViewerAuthorityGrant;
    if (accountRole === UserAccountRole.Member) return MemberAuthorityGrant;
    return null;
  };

  const getScopedAuthorityGrants = () => {
    const roleAuthorities =
      user && user.roles
        ? user.roles
            .map(role => parseScopedAuthorityGrant(role))
            .filter((authority): authority is ScopedAuthorityGrant => authority !== null)
        : [];
    const accountAuthorities =
      user && user.accountPermissions
        ? user.accountPermissions
            .map(function (permission) {
              const grant = toAuthorityGrant(permission.accountRole);
              return grant !== null
                ? new ScopedAuthorityGrant(
                    new AccountAuthorityScope(permission.accountId) as AuthorityScope,
                    grant,
                  )
                : null;
            })
            .filter((authority): authority is ScopedAuthorityGrant => authority !== null)
        : [];
    return roleAuthorities.concat(accountAuthorities);
  };

  const hasAuthority = (requestedAuthority: ScopedAuthorityGrant) =>
    getScopedAuthorityGrants().some(authority => authority.isAuthorized(requestedAuthority));

  const getHasAccountPermission = (accountId: string, grant: AuthorityGrant) =>
    hasAuthority(new ScopedAuthorityGrant(new AccountAuthorityScope(accountId), grant));

  const getHasAccountAdminPermission = (accountId: string): boolean =>
    getHasAccountPermission(accountId, AdminAuthorityGrant);

  const getHasAccountWritePermission = (accountId: string): boolean =>
    getHasAccountPermission(accountId, EditorAuthorityGrant);

  const getHasAccountReadPermission = (accountId: string): boolean =>
    getHasAccountPermission(accountId, ViewerAuthorityGrant);

  const getHasAccountAccessPermission = (accountId: string): boolean =>
    getHasAccountPermission(accountId, MemberAuthorityGrant);

  const getHasGlobalPermission = (grant: AuthorityGrant) =>
    !isGlobalAdminPermissionDisabled &&
    hasAuthority(new ScopedAuthorityGrant(GlobalAuthorityScope, grant));

  const getHasGlobalAdminPermission = (): boolean => getHasGlobalPermission(AdminAuthorityGrant);

  const getHasGlobalWritePermission = (): boolean => getHasGlobalPermission(EditorAuthorityGrant);

  const getHasGlobalReadPermission = (): boolean => getHasGlobalPermission(ViewerAuthorityGrant);

  return {
    isSignedIn,
    getHasAccountAdminPermission,
    getHasAccountWritePermission,
    getHasAccountReadPermission,
    getHasAccountAccessPermission,
    getHasAccountPermission,
    getHasGlobalReadPermission,
    getHasGlobalWritePermission,
    getHasGlobalAdminPermission,
    getHasGlobalPermission,
  };
};

export function AuthorizationProvider({ children }: { children: React.ReactNode }) {
  const { firebaseUser, loading: firebaseLoading } = useAuthenticationContext();
  const { data, loading, error, refetch } = useGetCurrentUserQuery({
    partialRefetch: true,
    skip: firebaseLoading || !firebaseUser,
  });

  const [isGlobalAdminPermissionDisabled, setIsGlobalAdminPermissionDisabled] =
    useLocalStorageState(LocalStorageKey.GlobalAdminDisabled, false);
  const currentUser = useMemo(() => data && data.currentUser, [data]);
  const checker = authorizationChecker(currentUser || undefined, isGlobalAdminPermissionDisabled);

  return (
    <AuthorizationContext.Provider
      value={{
        currentUser,
        firebaseUser,
        ...checker,
        isGlobalAdminPermissionDisabled,
        setIsGlobalAdminPermissionDisabled,
        refresh: refetch,
        loading: firebaseLoading || loading,
        error,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
}

export const useAuthorizationContext = () => useContext(AuthorizationContext);
