import { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { Maybe } from '@tellurian/ts-utils';
import _ from 'lodash';
import { useHistory } from 'react-router';
import { AuthProvider } from '../../../../generated/graphql';
import { SignInError, SignInSuccess } from '../../../security/SignIn/useThirdPartySignIn';
import useEventCallback from '../../utils/useEventCallback';
import { browserDoesNotSupportLoginWithRedirect } from '../../common/authentication/lib';
import { path, RouteId } from '../routing/Routes';
import { usePartnerApplication } from '../PartnerApplicationProvider';
import { usePartnerAuthenticationContext } from './PartnerAuthenticationContext';

const IsPendingRedirectStorageKey = 'firebase.isPendingRedirect';

const getIsPendingRedirect = () =>
  window.sessionStorage.getItem(IsPendingRedirectStorageKey) === 'true';

const clearIsPendingRedirect = () => {
  window.sessionStorage.removeItem(IsPendingRedirectStorageKey);
};

const storeIsPendingRedirect = () => {
  window.sessionStorage.setItem(IsPendingRedirectStorageKey, 'true');
};

type SignInResult = Maybe<SignInSuccess | SignInError>;

const useSignInRedirect = () => {
  const [signInResult, setSignInResult] = useState<SignInResult>();
  const [isPendingRedirect, setIsPendingRedirect] = useState(getIsPendingRedirect);

  useEffect(() => {
    firebase
      .auth()
      .getRedirectResult()
      .then(({ operationType, user, credential }) => {
        if (operationType && user && credential) {
          setSignInResult({
            operationType,
            user,
            credential,
          });
        }
      })
      .catch(err => {
        setSignInResult(_.pick(err, 'code', 'message'));
      })
      .finally(() => {
        clearIsPendingRedirect();
        setIsPendingRedirect(false);
      });
  }, []);

  const signIn = useEventCallback(async (provider: firebase.auth.OAuthProvider) => {
    storeIsPendingRedirect();
    setIsPendingRedirect(true);
    setSignInResult(undefined);
    return firebase.auth().signInWithRedirect(provider);
  });

  return { signInResult, isPendingRedirect, signIn };
};

const useSignInWithPopup = () => {
  const [signInResult, setSignInResult] = useState<SignInResult>();
  const [isPending, setIsPending] = useState(false);

  const signIn = useEventCallback(async (provider: firebase.auth.OAuthProvider) => {
    storeIsPendingRedirect();
    setSignInResult(undefined);
    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(({ operationType, user, credential }) => {
        if (operationType && user && credential) {
          setSignInResult({
            operationType,
            user,
            credential,
          });
        }
      })
      .catch(err => {
        setSignInResult(_.pick(err, 'code', 'message'));
      })
      .finally(() => {
        setIsPending(false);
      });
  });

  return { signInResult, signIn, isPending };
};

const useSignInWithAuthConfiguration = () => {
  const {
    signIn: signInRedirect,
    signInResult: redirectResult,
    isPendingRedirect,
  } = useSignInRedirect();
  const {
    signIn: signInWithPopup,
    signInResult: popupResult,
    isPending: isPendingPopup,
  } = useSignInWithPopup();

  const signIn = useEventCallback(async (authConfig: AuthProvider) => {
    const { providerId } = authConfig;
    if (providerId.startsWith('oidc.')) {
      const provider = new firebase.auth.OAuthProvider(providerId);

      return browserDoesNotSupportLoginWithRedirect()
        ? signInWithPopup(provider)
        : signInRedirect(provider);
    }
  });

  return {
    signIn,
    isPending: isPendingRedirect || isPendingPopup,
    signInResult: redirectResult || popupResult,
  };
};

const usePartnerSignIn = () => {
  const partnerApplication = usePartnerApplication();
  const { authConfiguration, isSignedIn } = usePartnerAuthenticationContext();
  const { signIn: signInWithAuthConfig, ...rest } = useSignInWithAuthConfiguration();
  const history = useHistory();
  const signIn = useEventCallback(async () => {
    // Auth state may have changed in the meantime
    if (!isSignedIn) {
      return signInWithAuthConfig(authConfiguration);
    }
  });

  const signInThenGoTo = useEventCallback(async (routeId: RouteId = 'PartnerHome') => {
    return signIn().then(() => {
      history.push(path(routeId)({ partnerApplication }));
    });
  });

  return { signInThenGoTo, signIn, authConfiguration, ...rest };
};

export default usePartnerSignIn;
