import React from 'react';
import Panel from '../../components/Panel';
import Flex from '../../components/Flex';
import PartnerLogo from '../lib/PartnerLogo';
import { usePartnerApplication } from '../PartnerApplicationProvider';
import { FCC } from '../../../../utils/types';
import style from './AuthPromptPanel.module.css';

const AuthPromptPanel: FCC = ({ children }) => {
  const partnerApplication = usePartnerApplication();
  return (
    <div className={style.container}>
      <Panel className={style.panel}>
        <Flex column size="L">
          <PartnerLogo partnerApplication={partnerApplication} variant="banner" />
          {children}
        </Flex>
      </Panel>
    </div>
  );
};

export default AuthPromptPanel;
