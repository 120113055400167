import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import {
  AuthenticationResult,
  CacheLookupPolicy,
  AuthError as AzureAuthError,
} from '@azure/msal-browser';
import { useLocation } from 'react-router';
import firebase from 'firebase/compat/app';
import { FCC } from '../../../utils/types';
import { trackError } from '../../../utils/errorTracking';
import { toUnfiAuthError, generateNonce, ACCESS_TOKEN_KEY } from '../unfiInsightsEmbedded/auth/lib';
import { useAuthorizationContext } from '../../security/AuthorizationContext';
import useAccessToken from '../common/authentication/useAccessToken';

type IdTokenResult = firebase.auth.IdTokenResult;

type UnfiInsightsOnPlatformAuthenticationInterface = {
  accessToken: string | undefined;
  isSignedInWithUnfiInsights: boolean | undefined;
};

const UnfiInsightsOnPlatformAuthentication =
  React.createContext<UnfiInsightsOnPlatformAuthenticationInterface>({
    accessToken: undefined,
    isSignedInWithUnfiInsights: undefined,
  });
export const useUnfiInsightsOnPlatformAuthenticationContext = () =>
  useContext(UnfiInsightsOnPlatformAuthentication);

const { REACT_APP_UNFI_INSIGHTS_SCOPES, REACT_APP_UNFI_INSIGHTS_PROVIDER_ID } = process.env;

const SCOPES = (REACT_APP_UNFI_INSIGHTS_SCOPES || '').split(',');

export const UnfiInsightsOnPlatformAuthenticationProvider: FCC = ({ children }) => {
  const { instance } = useMsal();
  const activeAccount = useAccount();
  const { accessToken, setAccessToken } = useAccessToken(ACCESS_TOKEN_KEY);
  const { firebaseUser } = useAuthorizationContext();
  const [idToken, setIdToken] = useState<IdTokenResult>();
  const location = useLocation();

  const onAzureError = useCallback((error: AzureAuthError) => {
    const authError = toUnfiAuthError(error);
    if (authError) {
      trackError(error.message, { ...authError, name: authError.message });
    }
  }, []);

  const signInWithRedirect = useCallback(() => {
    const nonce = generateNonce();
    instance
      .loginRedirect({ scopes: SCOPES, nonce: nonce.nonce })
      .catch(error => onAzureError(error))
      .then(() => {
        window.location.assign(new URL(location.pathname, location.search));
      });
  }, [instance, onAzureError, location]);

  useEffect(() => {
    if (activeAccount) {
      instance
        .acquireTokenSilent({
          scopes: SCOPES,
          cacheLookupPolicy: CacheLookupPolicy.AccessTokenAndRefreshToken,
        })
        .then((result: AuthenticationResult) => {
          setAccessToken(result.accessToken);
        })
        .catch((error: AzureAuthError) => {
          if (error.errorCode === 'token_refresh_required' || error.errorCode === 'invalid_grant') {
            signInWithRedirect();
          } else {
            onAzureError(error);
          }
        });
    }
  }, [activeAccount, instance, onAzureError, signInWithRedirect, setAccessToken]);

  useEffect(() => {
    if (firebaseUser && !idToken) {
      firebaseUser.getIdTokenResult().then((result: IdTokenResult) => setIdToken(result));
    }
  }, [firebaseUser, idToken]);

  const isSignedInWithUnfiInsights =
    idToken?.signInProvider === REACT_APP_UNFI_INSIGHTS_PROVIDER_ID;

  return (
    <UnfiInsightsOnPlatformAuthentication.Provider
      value={{ accessToken, isSignedInWithUnfiInsights }}
    >
      {children}
    </UnfiInsightsOnPlatformAuthentication.Provider>
  );
};
