import React, { useEffect, useState } from 'react';
import { useUnfiInsightsConfirmEmailAddressVerificationTokenMutation } from '../../../generated/graphql';
import 'firebase/compat/auth';
import { useAuthorizationContext } from '../../security/AuthorizationContext';
import { useParsedQuery } from '../../../utils/querystringUtils';
import { unfiEmbeddedPath } from '../unfiInsightsEmbedded/routing/Routes';
import { path } from '../crisp/routing/lib';
import { Feature, trackFeature } from '../../../utils/features';
import { RequestAccountPanel } from '../common/userLinking/RequestAccount';
import LandingPageTemplate, { UserLinking } from '../common/userLinkingPrompt/UserLinking';
import { VerifyEmailPanel } from './VerifyEmail';

const LandingPage: React.FC = () => {
  const [showRequestAccount, setShowRequestAccount] = useState(false);
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);

  return showRequestAccount ? (
    <div>
      <RequestAccountPanel showDetail={setShowRequestAccount} />
    </div>
  ) : showVerifyEmail ? (
    <div>
      <VerifyEmailPanel showDetail={setShowVerifyEmail} />
    </div>
  ) : (
    <>
      <UserLinking
        partnerName={'UNFI Insights'}
        onActivateYourAccount={() => setShowRequestAccount(true)}
        onLinkYourAccount={() => setShowVerifyEmail(true)}
        onBookDemo={() => {
          trackFeature(Feature.UnfiInsightsBookDemoClicked);
          window.open('https://www.gocrisp.com/unfi', '_blank');
        }}
      />
    </>
  );
};

export const UnfiInsightsOnPlatformVerification: React.FC = () => {
  const { firebaseUser } = useAuthorizationContext();
  const { token } = useParsedQuery<{ token: string }>();
  const hasVerifiedEmail = firebaseUser?.emailVerified;

  const [confirmToken, { data: confirmTokenResult }] =
    useUnfiInsightsConfirmEmailAddressVerificationTokenMutation();

  useEffect(() => {
    if (token) {
      confirmToken({
        variables: {
          input: {
            token,
          },
        },
      });
    }
  }, [token, confirmToken]);

  useEffect(() => {
    /**
     * The Firebase tokens (id and refresh tokens) are invalidated after we modify the Firebase user on the backend,
     * and the user must re-authenticate.
     *
     * To avoid prompting the user, we redirect to the embedded sign page, which will sign in the Firebase user using
     * Azure OAuth credentials, and then we redirect back to the Crisp Platform.
     */
    if (firebaseUser && confirmTokenResult && !hasVerifiedEmail) {
      window.location.replace(
        unfiEmbeddedPath('UnfiInsightsEmbeddedSignIn')() +
          '?redirectUri=' +
          path('AccountSelect')(),
      );
    } else if (hasVerifiedEmail && token) {
      window.location.replace(path('AccountSelect')());
    }
  }, [confirmTokenResult, hasVerifiedEmail, firebaseUser, token]);

  if (token) {
    return null;
  }

  return (
    <LandingPageTemplate>
      <LandingPage />
    </LandingPageTemplate>
  );
};
