import { Theme } from '../../components/Theme';
import CrispApp from '../CrispApp';
import { ThemeOption } from './UserPreferencesProvider';

export const ThemeOptionToTheme: Record<ThemeOption, Theme> = {
  'Crisp Lettuce': Theme.Lettuce,
  UNFI: Theme.Unfi,
  Rema: Theme.Rema,
  Advantage: Theme.Advantage,
  'High Impact': Theme.HighImpact,
  Dot: Theme.Dot,
  Instacart: Theme.Instacart,
};

export const AccountIdToTheme: Record<string, Theme> = {
  81709: Theme.HighImpact,
  81949: Theme.Instacart,
};

const AccountBoundThemes = new Set(Object.values(AccountIdToTheme));
export const isAccountBoundTheme = (theme: Theme) => AccountBoundThemes.has(theme);

export const getAccountBoundTheme = () => {
  const accountId = CrispApp.getAccountId();
  return accountId ? AccountIdToTheme[accountId] : undefined;
};
