/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { AuthProvider } from '../../../../generated/graphql';
import logger from '../../../../services/logger';

const AUTH_CONFIG_ENDPOINT = `${process.env.REACT_APP_REST_API_URL}/api/v1/sso/partner_provider_config`;

type UseGetAuthConfigurationParams = {
  providerId?: string;
  partnerApplication: string | undefined;
};

const DotTestAuthConfig = {
  id: '',
  accountId: '',
  providerId: 'oidc.dotfoods-test.com',
  displayName: 'Dot Foods Test',
  subdomain: 'not-specified',
  emailDomains: [],
  scopes: ['email'],
  knownAuthorities: [''],
  redirectUrl: '',
  providerType: '',
  clientId: '',
  enabled: true,
  issuer: '',
  logoUrl: '',
  logoutRedirectUrl: '',
};

export const useGetAuthConfiguration = ({
  providerId = '',
  partnerApplication,
}: UseGetAuthConfigurationParams) => {
  const [authConfiguration, setAuthConfiguration] = useState<AuthProvider>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${AUTH_CONFIG_ENDPOINT}?partnerApplication=${partnerApplication}`)
      .then(response => {
        if (response.ok) {
          return response.json().then(data => {
            setAuthConfiguration(data);
          });
        }
      })
      .catch(error => {
        logger.error('Error fetching auth configuration', error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [providerId, partnerApplication]);

  return {
    authConfiguration,
    error,
    loading,
  };
};
