import { Resource } from '../components/ResourceCards/lib';
import { AcademyResource, KnowledgeBaseResource, RecentUpdatesResource } from '../common/Resources';

/**
 * For now, partner resource links are hard-coded to align with Dot.
 * We should eventually refactor this to include a resolver that accepts
 * a partnerApplication and returns links based on that.
 */

const {
  REACT_APP_DOT_PARTNER_HELP_URL,
  REACT_APP_DOT_PARTNER_ACADEMY_URL,
  REACT_APP_DOT_PARTNER_UPDATES_URL,
} = process.env;

export const PartnerResources = [
  KnowledgeBaseResource(REACT_APP_DOT_PARTNER_HELP_URL),
  AcademyResource(REACT_APP_DOT_PARTNER_ACADEMY_URL),
  RecentUpdatesResource(REACT_APP_DOT_PARTNER_UPDATES_URL),
] as const satisfies Resource[];
