import React, { useMemo } from 'react';
import { ReactComponent as LogOutIcon } from '../../../../assets/icons/lettuce/logout.svg';
import { ReactComponent as DocumentIcon } from '../../../../assets/icons/lettuce/document.svg';
import { ItemSeparator } from '../../components/Menu/RenderMenuItem';
import Menu from '../../components/Menu';
import { usePartnerSettings } from '../PartnerSettings';
import { MenuItemSpec } from '../../components/Menu/lib';
import { ExternalLinkTarget } from '../../components/ExternalLink';
import { path } from '../routing/Routes';
import { usePartnerApplication } from '../PartnerApplicationProvider';
import PartnerUserDetails from './PartnerUserDetails';

const UserSettingsMenu: React.FC = () => {
  const partnerApplication = usePartnerApplication();
  const partnerSettings = usePartnerSettings();
  const items = useMemo(
    () =>
      [
        partnerSettings.termsAndConditionsUrl && {
          label: 'Terms and Conditions',
          Icon: DocumentIcon,
          href: partnerSettings.termsAndConditionsUrl,
          target: ExternalLinkTarget.partnerTermsAndConditions,
        },
        {
          label: 'Log out',
          Icon: LogOutIcon,
          path: path('PartnerSignOut')({ partnerApplication }),
        },
      ].filter(Boolean) as MenuItemSpec[],
    [partnerSettings, partnerApplication],
  );
  return (
    <>
      <PartnerUserDetails />
      <div className="mhs mbs">
        <ItemSeparator />
        <Menu aria-label="Settings menu" items={items} />
      </div>
    </>
  );
};

export default UserSettingsMenu;
