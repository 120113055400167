import React, { ReactNode, Suspense, useMemo } from 'react';
import { Route, Switch } from 'react-router';
import Spinner from '../Spinner';
import RedirectRoute from './RedirectRoute';
import RenderRoute from './RenderRoute';
import { RedirectMap, RouteDeclaration, RouteOptions } from './lib';
import RouteSwitchProvider, {
  RouteSwitchContextDefaults,
  RouteSwitchContextInterface,
} from './RouteSwitchContext';

// This must be a used as a function call and not a component because `<Switch>` will not handle React fragments.
const RenderRedirects = (redirects: RedirectMap) =>
  Object.entries(redirects).map(([fromPath, redirectTo]) => RedirectRoute(fromPath, redirectTo));

type RouteSwitchParams = {
  routes: RouteDeclaration[];
  redirects?: RedirectMap;
  defaultRouteOptions?: RouteOptions;
  additionalRoutes?: ReactNode;
} & Partial<RouteSwitchContextInterface>;

const RouteSwitch: React.FC<RouteSwitchParams> = ({
  routes,
  redirects,
  defaultRouteOptions,
  additionalRoutes,
  useIsSignedIn = RouteSwitchContextDefaults.useIsSignedIn,
  getSignInPath = RouteSwitchContextDefaults.getSignInPath,
}) => {
  const routesToUse = useMemo(
    () => routes.map(r => ({ ...defaultRouteOptions, ...r })),
    [routes, defaultRouteOptions],
  );

  return (
    <RouteSwitchProvider useIsSignedIn={useIsSignedIn} getSignInPath={getSignInPath}>
      <Suspense fallback={<Spinner />}>
        <Switch>
          {redirects && RenderRedirects(redirects)}
          {routesToUse.map(route => {
            return (
              <Route
                key={route.id}
                exact={true}
                path={route.path}
                render={params => <RenderRoute route={route} {...params} />}
              />
            );
          })}
          {additionalRoutes}
        </Switch>
      </Suspense>
    </RouteSwitchProvider>
  );
};

export default RouteSwitch;
