import React, { useEffect, useState } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { useAuthenticationContext } from '../../common/authentication/AuthenticationContext';
import Avatar, { AvatarContainer } from './index';

// Do not retrieve the user photo in DEV, this almost always leads to a broken image due to a 403 error.
const shouldUseFirebaseUserPhoto = process.env.NODE_ENV === 'production';

/**
 * Check if the photo at photoUrl is accessible. The predicate is used to avoid rendering a broken image
 * placeholder with alt text, preventing a jarring experience.
 * @param photoUrl
 */
const isUserPhotoAvatarAccessible = async (photoUrl: string): Promise<boolean> => {
  try {
    const result = await fetch(photoUrl);
    return result.ok;
  } catch (err) {}

  return false;
};

const CurrentUserAvatar: React.FC = () => {
  const { firebaseUser } = useAuthenticationContext();
  const [photoUrl, setPhotoUrl] = useState<Maybe<string>>();

  useEffect(() => {
    if (shouldUseFirebaseUserPhoto) {
      const url = firebaseUser?.photoURL;
      (async () => {
        if (url && (await isUserPhotoAvatarAccessible(url))) {
          setPhotoUrl(url);
        }
      })();
    }
  }, [firebaseUser]);

  if (firebaseUser) {
    const { displayName, photoURL } = firebaseUser;
    if (displayName || photoURL) {
      return <Avatar name={displayName!} photoUrl={photoUrl} />;
    }
  }

  // Empty avatar
  return <AvatarContainer />;
};

export default CurrentUserAvatar;
