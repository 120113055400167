import { Maybe, maybe } from '@tellurian/ts-utils';
import {
  CredentialVerificationStep,
  VerificationDetailsFragment,
  ConnectorConfigurationFragment,
  CredentialVerificationStepProgress,
} from '../../../generated/graphql';

export const getFailingStep = (
  verification?: VerificationDetailsFragment,
): Maybe<CredentialVerificationStep> =>
  verification?.steps.find(step => step.progress === CredentialVerificationStepProgress.Failure);

const isCredentialVerificationFailing = (
  configuration: Pick<ConnectorConfigurationFragment, 'verification'>,
): boolean => !!getFailingStep(maybe(configuration.verification));

export default isCredentialVerificationFailing;
