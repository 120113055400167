import { maybe, Maybe } from '@tellurian/ts-utils';
import pathToRegexp from 'path-to-regexp';
import {
  encodeRouteParams,
  RouteDeclaration,
  RouteIdToRoute,
  RouteParams,
} from '../../components/routing/lib';
import PartnerHomePage from '../../partner/pages/HomePage';
import SignInPage from '../../partner/pages/SignInPage';
import PartnerAnalyticsPage from '../pages/AnalyticsPage';
import { PartnerApplication } from '../../components/lib/Account';
import SignOutPage from '../pages/SignOutPage';
import PartnerDebugPage from '../pages/DebugPage';

export const Routes = [
  {
    id: 'PartnerAuthCallback',
    path: '/p/:partnerApplication/signin/callback',
    Render: SignInPage,
  },
  {
    id: 'PartnerSignIn',
    path: '/p/:partnerApplication/signin',
    Render: SignInPage,
  },
  {
    id: 'PartnerSignOut',
    path: '/p/:partnerApplication/signout',
    Render: SignOutPage,
  },
  {
    id: 'PartnerHome',
    path: '/p/:partnerApplication',
    Render: PartnerHomePage,
    requireAuth: true,
  },
  {
    id: 'PartnerAnalytics',
    path: '/p/:partnerApplication/reports',
    Render: PartnerAnalyticsPage,
    requireAuth: true,
  },
  {
    id: 'PartnerDebug',
    path: '/p/:partnerApplication/debug',
    Render: PartnerDebugPage,
    requireAuth: true,
  },
] as const satisfies RouteDeclaration[];

export type RouteId = (typeof Routes)[number]['id'];

export const RouteById = Routes.reduce<RouteIdToRoute<RouteId>>(
  (acc, r) => ({ ...acc, [r.id]: r }),
  {} as RouteIdToRoute<RouteId>,
);

const PartnerUrlRegExp = /\/p\/([^/]+)/;

export const getPartnerApplicationFromPathname = (pathname: string): Maybe<PartnerApplication> => {
  return maybe(PartnerUrlRegExp.exec(pathname)?.[1]) as Maybe<PartnerApplication>;
};

const compilePath = pathToRegexp.compile;
export const path = (routeId: RouteId): ((params?: RouteParams) => string) => {
  return params => compilePath(RouteById[routeId].path)(params && encodeRouteParams(params));
};
