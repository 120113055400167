import React, { useContext, useEffect, useState } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { maybe } from '@tellurian/ts-utils';
import { LDContext } from 'launchdarkly-js-sdk-common';
import { PageErrorCode } from '../../../../generated/graphql';
import { useAuthenticationContext } from '../authentication/AuthenticationContext';
import usePageError from '../../../errorHandling/PageError/usePageError';
import { Feature, trackFeature } from '../../../../utils/features';

export type FeatureFlagInterface = {
  featureFlagsReady: boolean;
};

export const FeatureFlagContext = React.createContext<FeatureFlagInterface>({
  featureFlagsReady: false,
});

export const FeatureFlagsDisabled = process.env.REACT_APP_DISABLE_LAUNCH_DARKLY === 'true';

export function FeatureFlagsProvider({ children }: { children: React.ReactNode }) {
  const context = useAuthenticationContext();
  const [featureFlagsReady, setFeatureFlagsReady] = useState(FeatureFlagsDisabled);
  const { setErrorMessage } = usePageError();
  const ldClient = useLDClient();

  const user = context.firebaseUser;

  useEffect(() => {
    if (!FeatureFlagsDisabled && user && ldClient) {
      const ldUser: LDContext = {
        key: user.uid,
        name: maybe(user.displayName),
        email: maybe(user.email),
        avatar: maybe(user.photoURL),
      };
      ldClient.identify(ldUser).then(
        () => setFeatureFlagsReady(true),
        rejection => {
          setErrorMessage(PageErrorCode.FeatureFlagError);
          trackFeature(Feature.FeatureFlagError, { user, rejection });
        },
      );
    }
  }, [user, ldClient, setErrorMessage]);

  return (
    <FeatureFlagContext.Provider value={{ featureFlagsReady }}>
      {children}
    </FeatureFlagContext.Provider>
  );
}

export const useFeatureFlagsReady = () => useContext(FeatureFlagContext).featureFlagsReady;
