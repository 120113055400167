import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useGetAccountQuery } from '../../../../generated/graphql';
import {
  RenderAccountDetails,
  useAccountDetails,
} from '../../../../pageTemplates/AccountTemplate/AccountDetails';
import { MenuItemSpec } from '../../components/Menu/lib';
import Menu from '../../components/Menu';
import { Feature } from '../../../../utils/features';
import { ItemSeparator } from '../../components/Menu/RenderMenuItem';
import { useAuthorizationContext } from '../../../security/AuthorizationContext';
import useToggleKeyboardShortcutHelpShortcut from '../../../keyboardSupport/help/useToggleKeyboardShortcutHelp';
import { DisableAdminShortcutSpec } from '../../../security/GlobalAdminPermissionKeyboardToggle';
import { useUpdatePopoverOnContentLoadStateChange } from '../../components/NavRail/PopoverActionContext';
import { getSalesforceAccountUrl } from '../../../AccountManager/AccountDetails/General/lib';
import { path } from '../routing/lib';
import { AccountDetailsTab } from '../routing/Tabs';
import { useUserPreferencesContext } from '../UserPreferences/UserPreferencesProvider';
import { UserPreferencesShortcutSpec } from '../UserPreferences/editor/UserPreferencesEditor';

const AccountDetails: React.FC<{ accountId: string }> = ({ accountId }) => {
  const account = useAccountDetails(accountId);
  useUpdatePopoverOnContentLoadStateChange(!!account);
  return account ? <RenderAccountDetails {...account} /> : null;
};

const useGetAccountBoundMenuItems = (accountId?: string): MenuItemSpec[] => {
  const { data } = useGetAccountQuery({ variables: { accountId: accountId! }, skip: !accountId });
  if (!accountId) {
    return [];
  }

  const salesforceAccountId = data?.account.salesforceAccountId;
  const accountDetailsPath = (params: { tab?: string; identifier?: string }) =>
    path('AccountDetails')({ accountId, ...params });
  return [
    {
      label: 'Account settings',
      path: accountDetailsPath({ tab: AccountDetailsTab.Overview }),
    },
    {
      label: 'Configure active connectors',
      path: accountDetailsPath({ tab: AccountDetailsTab.Connectors }),
    },
    {
      label: 'Source connector status',
      path: accountDetailsPath({ tab: AccountDetailsTab.Connectors }),
      featureId: Feature.InboundDataStatusLinkClick,
    },
    salesforceAccountId && {
      label: 'Sales account',
      href: getSalesforceAccountUrl(salesforceAccountId),
      featureId: Feature.AdminMenuOpenSalesforceAccount,
    },
    {
      separator: true,
    },
  ].filter(Boolean) as MenuItemSpec[];
};

const useGlobalMenuItems = (): MenuItemSpec[] => {
  const { setIsGlobalAdminPermissionDisabled } = useAuthorizationContext();
  const toggleShortcutHelpShortcut = useToggleKeyboardShortcutHelpShortcut();
  const { toggleEditor } = useUserPreferencesContext();

  return useMemo(() => {
    return [
      {
        label: 'All accounts',
        path: path('Admin')(),
      },
      {
        label: 'User account permissions',
        path: path('UserAccountPermissions')(),
      },
      {
        label: 'Account monitor',
        path: path('AccountMonitor')(),
      },
      {
        label: 'Source table labels',
        path: path('ManageSourceLabels')(),
      },
      {
        label: 'Terms of service',
        path: path('TermsOfServiceVersions')(),
      },
      {
        label: 'Settings',
        keyboardShortcut: {
          ...UserPreferencesShortcutSpec,
          fn: toggleEditor,
        },
        registerShortcut: false,
      },
      {
        label: 'View as non-admin',
        keyboardShortcut: {
          ...DisableAdminShortcutSpec,
          fn: () => setIsGlobalAdminPermissionDisabled(true),
        },
        registerShortcut: false,
      },
      {
        label: 'View keyboard shortcuts',
        keyboardShortcut: toggleShortcutHelpShortcut,
        registerShortcut: false,
      },
    ];
  }, [setIsGlobalAdminPermissionDisabled, toggleShortcutHelpShortcut, toggleEditor]);
};

type GlobalAdminMenuProps = {
  accountId?: string;
};

const GlobalAdminMenu: React.FC<GlobalAdminMenuProps> = ({ accountId }) => {
  const globalItems = useGlobalMenuItems();
  const accountBoundItems = useGetAccountBoundMenuItems(accountId);
  const items = [...accountBoundItems, ...globalItems];

  return (
    <>
      {accountId && <AccountDetails accountId={accountId} />}
      <div className={clsx('mhs', 'mbs', { mts: !accountId })}>
        {accountId && <ItemSeparator />}
        <Menu aria-label="Global admin menu" items={items} />
      </div>
    </>
  );
};

export default GlobalAdminMenu;
