import React from 'react';
import style from './UserDetails.module.css';

export type UserDetailsProps = {
  displayName: string;
  email: string;
};

const UserDetails: React.FC<UserDetailsProps> = ({ displayName, email }) => {
  return (
    <div className={style.userDetails}>
      <div className={style.userName}>{displayName}</div>
      <div className={style.userEmail}>{email}</div>
    </div>
  );
};

export default UserDetails;
