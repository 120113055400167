import { Maybe, noop } from '@tellurian/ts-utils';
import { AccountInfo } from '@azure/msal-browser';
import firebase from 'firebase/compat/app';
import { LocalStorage, LocalStorageKey } from '../../../utils/localStorage';
import { UserPreferences } from '../partner/preferences/UserPreferences';
import { getAccountId, path } from './routing/lib';
import { CustomSsoProvider } from './authentication/SignIn';

export const CrispAppNoop = () => {
  console.log('This operation is currently unassigned.');
};

/**
 * This object should only be used to include members whose import would otherwise
 * cause circular dependency errors.
 */
type CrispAppGlobal = {
  path: typeof path;
  getAccountId: typeof getAccountId;
  dataDictionary: {
    version: string;
  };
  updateAccountData: () => void;
  partnerMiniSite: {
    getFirebaseUser: () => Maybe<Pick<firebase.User, 'displayName' | 'email' | 'providerData'>>;
    getAzureUser: () => Maybe<AccountInfo>;
    // When enabled, the partner mini site will be loaded in the Crisp application (without 3rd party authentication)
    // and will render demo data. This should be temporary until we have a `partner-demo` URL.
    setDemoSiteEnabled: (enabled: boolean) => void;
    isDemoSiteEnabled: () => boolean;
    updatePreferences: (nextPreferences: UserPreferences) => void;
    getPreferences: () => Maybe<UserPreferences>;
  };
  unfiInsightsEmbedded: {
    getFirebaseUser: () => Maybe<Pick<firebase.User, 'displayName' | 'email' | 'providerData'>>;
    getAzureUser: () => Maybe<AccountInfo>;
  };
  debug: {
    setCustomLoginProvider: (provider: Maybe<CustomSsoProvider>) => void;
  };
};

// Include noop defaults. These will be overwritten on application load or when chunks load.
const CrispApp: CrispAppGlobal = {
  path: () => () => '',
  getAccountId: () => '',
  dataDictionary: { version: 'unknown' },
  updateAccountData: CrispAppNoop,
  partnerMiniSite: {
    getFirebaseUser: () => undefined,
    getAzureUser: () => undefined,
    setDemoSiteEnabled: enabled => {
      LocalStorage.setItem(LocalStorageKey.DemoSiteEnabled, enabled);
      return enabled;
    },
    isDemoSiteEnabled: () => !!LocalStorage.getItem(LocalStorageKey.DemoSiteEnabled),
    updatePreferences: noop,
    getPreferences: () => undefined,
  },
  unfiInsightsEmbedded: {
    getFirebaseUser: () => undefined,
    getAzureUser: () => undefined,
  },
  debug: {
    setCustomLoginProvider: provider => {
      localStorage.setItem('crispApp.debug.customProvider', JSON.stringify(provider));
    },
  },
};
window['CrispApp'] = CrispApp;

export default CrispApp;
