import React, { useContext } from 'react';
import { PartnerApplication } from '../components/lib/Account';
import { FCC } from '../../../utils/types';

type PartnerApplicationProviderContextInterface = {
  partnerApplication: PartnerApplication;
};

export const PartnerApplicationContext =
  React.createContext<PartnerApplicationProviderContextInterface>({
    partnerApplication: PartnerApplication.Dot,
  });

/**
 * The purpose of this context is purely to make the partnerApplication available to all components
 * in a hierarchy, and to avoid having to pass partnerApplication as a prop to every single component.
 * @param children
 * @param partnerApplication
 * @constructor
 */
const PartnerApplicationProvider: FCC<{ partnerApplication: PartnerApplication }> = ({
  children,
  partnerApplication,
}) => {
  return (
    <PartnerApplicationContext.Provider value={{ partnerApplication }}>
      {children}
    </PartnerApplicationContext.Provider>
  );
};

export default PartnerApplicationProvider;

export const usePartnerApplication = () => useContext(PartnerApplicationContext).partnerApplication;
