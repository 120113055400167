import React from 'react';
import { Route } from 'react-router';
import RouteSwitch from '../../components/routing/RouteSwitch';
import { RouteOptions } from '../../components/routing/lib';
import NotFoundInAccount from '../../components/routing/NotFoundInAccount';
import NotFound from '../../components/routing/NotFound';
import { UnfiEmbeddedRoutes } from './Routes';

const NotFoundRoutes = (
  <>
    <Route exact path={'/:accountId/*'} component={NotFoundInAccount} />
    <Route exact path={'*'} component={NotFound} />
  </>
);

// Do not require authentication for UnfiInsightsEmbedded routes. This is not strictly necessary
// but is included for clarity.
const DefaultRouteOptions: RouteOptions = { requireAuth: false };

const AppRouteSwitch: React.FC = () => (
  <RouteSwitch
    routes={UnfiEmbeddedRoutes}
    defaultRouteOptions={DefaultRouteOptions}
    additionalRoutes={NotFoundRoutes}
  />
);

export default AppRouteSwitch;
