import React from 'react';
import clsx from 'clsx';
import style from './index.module.css';

type HorizontalSeparatorProps = {
  children?: string;
  className?: string;
};

const HorizontalSeparator: React.FC<HorizontalSeparatorProps> = ({ children, className }) => {
  return <div className={clsx(style.separator, className)}>{children}</div>;
};

export default HorizontalSeparator;

export const OrSeparator: React.FC<Pick<HorizontalSeparatorProps, 'className'>> = props => (
  <HorizontalSeparator {...props}>or</HorizontalSeparator>
);
