import React, { useContext, useEffect, useMemo } from 'react';
import { AuthProvider } from '../../../../generated/graphql';
import {
  AuthenticationContext,
  AuthenticationContextInterface,
  AuthenticationProvider,
} from '../../common/authentication/AuthenticationContext';
import { FCC } from '../../../../utils/types';
import Spinner from '../../components/Spinner';
import logger from '../../../../services/logger';
import { usePartnerApplication } from '../PartnerApplicationProvider';
import NotFound from '../../components/routing/NotFound';
import { useGetAuthConfiguration } from './useGetAuthConfiguration';

type PartnerAuthenticationContextInterface = {
  authConfiguration: AuthProvider;
};

export const PartnerAuthenticationContext =
  React.createContext<PartnerAuthenticationContextInterface>({
    authConfiguration: {
      id: '',
      accountId: '',
      providerId: '',
      displayName: 'Unknown',
      clientId: '',
      enabled: true,
    },
  });

const PartnerAuthenticationProvider: FCC = ({ children }) => {
  const partnerApplication = usePartnerApplication();
  const { authConfiguration, loading, error } = useGetAuthConfiguration({
    partnerApplication,
  });

  useEffect(() => {
    if (error) {
      logger.error('Partner Auth Error: Error fetching auth configuration', error);
    }
  }, [error]);

  if (loading) {
    return <Spinner />;
  }

  if (error || !authConfiguration) {
    // If there is no auth configuration for this partnerApplication, then this has not been configured,
    // and it should be the same result as an invalid URL.
    // We could/might handle the error differently later.
    return <NotFound />;
  }

  return (
    <PartnerAuthenticationContext.Provider value={{ authConfiguration }}>
      <AuthenticationProvider>{children}</AuthenticationProvider>
    </PartnerAuthenticationContext.Provider>
  );
};

export default PartnerAuthenticationProvider;

export const usePartnerAuthenticationContext = (): PartnerAuthenticationContextInterface &
  AuthenticationContextInterface => {
  const { authConfiguration } = useContext(PartnerAuthenticationContext);
  const { firebaseUser, loading } = useContext(AuthenticationContext);

  const isSignedInWithPartnerAuthProvider = useMemo(() => {
    if (firebaseUser) {
      return firebaseUser.providerData.some(
        record => record?.providerId === authConfiguration.providerId,
      );
    }

    return false;
  }, [firebaseUser, authConfiguration]);

  return {
    authConfiguration,
    isSignedIn: isSignedInWithPartnerAuthProvider,
    loading,
    firebaseUser,
  };
};
