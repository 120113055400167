import { AnyObject } from '@tellurian/ts-utils';
import _ from 'lodash';
import useEventCallback from '../../utils/useEventCallback';
import { usePartnerAccount } from '../lib/PartnerContext';

export const AnalyticsEvents = [
  'mini-site|featured-report|YOY-sales',
  'mini-site|featured-report|DC-Inventory',
  'mini-site|featured-report|Distribution-expansion',
  'mini-site|resources-tile|KB',
  'mini-site|resources-tile|Academy',
  'mini-site|resources-tile|Recent-updates',
  'mini-site|nav|analytics',
  'mini-site|nav|home',
  'mini-site|nav|help',
  'mini-site|nav|settings',
  'mini-site|nav|resources|KB',
  'mini-site|nav|resources|Academy',
  'mini-site|nav|resources|Recent-updates',
  'mini-site|nav|Crisp',
  'mini-site|show-featured-reports',
  'mini-site|featured-report|hide-featured-reports',
  // Report selection events
  'mini-site|workspace-changed',
  'mini-site|report-changed',
  'mini-site|page-changed',
  'mini-site|bookmark-changed',
  'mini-site|selection-changed',
  'mini-site|preset-changed',
  // Recent report selection
  'mini-site|home|recent-report-selected',
  'mini-site|workspace-home|recent-report-selected',
] as const;

export type AnalyticsEvent = (typeof AnalyticsEvents)[number];

const analyticsFactory = <T extends string = string>() => {
  const getAnalytics = () => window['analytics'];
  const hasAnalytics = () => !!getAnalytics();
  const isEventConsoleLogEnabled = process.env.REACT_APP_DISABLE_EVENT_LOG !== 'true';

  const trackEvent = (event: T, attributes?: AnyObject) => {
    if (hasAnalytics()) {
      getAnalytics().track(event, attributes);
    } else if (isEventConsoleLogEnabled) {
      console.log('AnalyticsEvent: ' + event, attributes);
    }
  };

  return {
    trackEvent,
  };
};

const { trackEvent } = analyticsFactory<AnalyticsEvent>();
export { trackEvent };

export const useTrackPartnerAnalyticsEvent = () => {
  const account = usePartnerAccount();
  return useEventCallback((event: AnalyticsEvent, attributes?: AnyObject) => {
    trackEvent(event, {
      ...attributes,
      partner: account.partnerApplication,
    });
  });
};

// Convenience factory fn to be used as onClick/onMouseDown prop
export const eventTrack = _.memoize((event: AnalyticsEvent, attributes?: AnyObject) => () => {
  trackEvent(event, attributes);
});
