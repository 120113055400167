import React, { useCallback, useEffect } from 'react';
import { Route, RouteComponentProps } from 'react-router';
import _ from 'lodash';
import logger from '../../../../services/logger';
import { addAuthenticationListener, useUserGroupTracking } from '../../../security/lib';
import { useAuthorizationContext } from '../../../security/AuthorizationContext';
import { getEmailForRetailAnalyticsUser } from '../../utils';

const segmentVar = process.env.REACT_APP_ALWAYS_ENABLE_SEGMENT_TRACKER;
const enableSegment =
  process.env.NODE_ENV === 'production' || !!(segmentVar && JSON.parse(segmentVar));

function Identify({ useEmailAsUserId = false }: { useEmailAsUserId?: boolean }) {
  const { currentUser } = useAuthorizationContext();
  const { onAnalyticsSetupComplete, onCurrentUserRetrieved } = useUserGroupTracking();

  useEffect(() => {
    if (currentUser) {
      onCurrentUserRetrieved(currentUser);
    }
  }, [currentUser, onCurrentUserRetrieved]);

  const init = useCallback(() => {
    return addAuthenticationListener(user => {
      const analytics = window['analytics'];
      const email = user?.email || getEmailForRetailAnalyticsUser() || '';
      user &&
        (user.uid || email) &&
        analytics?.identify(
          useEmailAsUserId && email ? email : user.uid,
          {
            name: user.displayName,
            email,
          },
          onAnalyticsSetupComplete,
        );
      if (!user?.email && email) {
        logger.info('Identified user with msal account username', { email });
      } else if (user && !email) {
        logger.info('Cannot find email for user', _.pick(user, 'uid', 'displayName', 'providerId'));
      }
    });
  }, [onAnalyticsSetupComplete, useEmailAsUserId]);

  useEffect(() => init(), [init]);
  return null;
}

export function PageTracker(props: RouteComponentProps) {
  const sendPageChange = (pathname: string, search = '') => {
    const analytics = window['analytics'];
    if (analytics?.user?.().id()) {
      analytics.page({ path: `${pathname}?${search}` });
    }
  };

  const { pathname, search } = props.location;
  useEffect(() => {
    sendPageChange(pathname, search);
  }, [pathname, search]);

  return null;
}

export default function SegmentTracker({
  useEmailAsUserId = false,
}: {
  useEmailAsUserId?: boolean;
}) {
  return enableSegment ? (
    <>
      <Identify useEmailAsUserId={useEmailAsUserId} />
      <Route component={PageTracker} />
    </>
  ) : null;
}
