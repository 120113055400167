import React, { useContext, useEffect, useState } from 'react';
import { maybe, Maybe } from '@tellurian/ts-utils';
import { addAuthenticationListener } from '../../../security/lib';
import { FirebaseUser } from './lib';

const isSignedIn = (user?: FirebaseUser) => !!user;

export type AuthenticationContextInterface = {
  firebaseUser: Maybe<FirebaseUser>;
  isSignedIn: boolean;
  loading: boolean;
};

export const AuthenticationContext = React.createContext<AuthenticationContextInterface>({
  firebaseUser: undefined,
  isSignedIn: false,
  loading: true,
});

export function AuthenticationProvider({ children }: { children: React.ReactNode }) {
  const [firebaseUser, setFirebaseUser] = useState<Maybe<FirebaseUser>>();
  const [loading, setLoading] = useState(true);

  const onAuthenticated = async (user: FirebaseUser | null) => {
    setFirebaseUser(maybe(user));
    setLoading(false);
  };

  useEffect(() => addAuthenticationListener(onAuthenticated), []);

  return (
    <AuthenticationContext.Provider
      value={{
        isSignedIn: isSignedIn(firebaseUser),
        firebaseUser,
        loading,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

export function useAuthenticationContext() {
  return useContext(AuthenticationContext);
}
