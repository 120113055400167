import { useMemo } from 'react';
import { PartnerApplication } from '../components/lib/Account';
import { Theme } from '../components/Theme';
import { usePartnerApplication } from './PartnerApplicationProvider';

export type FeaturedReport = {
  target: {
    workspaceName?: string;
    reportName: string;
    pageDisplayName: string;
  };
  title: string;
  description: string;
  iconName?: string;
};

const PartnerLogoNames = ['dot', 'crisp'] as const;
export type PartnerLogoName = (typeof PartnerLogoNames)[number];

type Settings = {
  theme: Theme;
  logo: PartnerLogoName;
  documentTitle: string;
  featuredReports: FeaturedReport[];
  termsAndConditionsUrl?: string;
};

const PartnerSettings: Readonly<Record<PartnerApplication, Settings>> = {
  [PartnerApplication.Dot]: {
    documentTitle: 'Crisp at Dot',
    theme: Theme.Dot,
    logo: 'dot',
    featuredReports: [
      {
        title: 'YoY Sales',
        description: 'Compare sales to last year to discover what drives growth',
        iconName: 'YoYSales',
        target: {
          reportName: 'Performance Insights',
          pageDisplayName: 'Supplier Trends',
        },
      },
      {
        title: 'Distribution Center Inventory',
        description: 'Monitor on-hand DC inventory to proactively replenish supply',
        iconName: 'DistributionInventory',
        target: {
          reportName: 'Inventory Flow Analysis',
          pageDisplayName: 'DC Details',
        },
      },
      {
        title: 'Distribution expansion',
        description: 'Find top-performing stores in your category to optimize your distribution',
        iconName: 'DistributionExpansion',
        target: {
          reportName: 'Distribution Analysis',
          pageDisplayName: 'Expansion Summary',
        },
      },
    ],
    termsAndConditionsUrl: 'https://www.dotfoods.com/Crisp-at-Dot/',
  },
  [PartnerApplication.DotDemo]: {
    documentTitle: 'Crisp at Dot',
    theme: Theme.Dot,
    logo: 'dot',
    featuredReports: [
      {
        title: 'YoY Sales',
        description: 'Compare sales to last year to discover what drives growth',
        iconName: 'YoYSales',
        target: {
          reportName: 'Performance Insights',
          pageDisplayName: 'Supplier Trends',
        },
      },
      {
        title: 'Distribution Center Inventory',
        description: 'Monitor on-hand DC inventory to proactively replenish supply',
        iconName: 'DistributionInventory',
        target: {
          reportName: 'Inventory Flow Analysis',
          pageDisplayName: 'DC Details',
        },
      },
      {
        title: 'Distribution expansion',
        description: 'Find top-performing stores in your category to optimize your distribution',
        iconName: 'DistributionExpansion',
        target: {
          reportName: 'Distribution Analysis',
          pageDisplayName: 'Expansion Summary',
        },
      },
    ],
    termsAndConditionsUrl: 'https://www.dotfoods.com/Crisp-at-Dot/',
  },
  [PartnerApplication.DotInternal]: {
    documentTitle: 'Crisp at Dot',
    theme: Theme.Dot,
    logo: 'dot',
    featuredReports: [
      {
        title: 'YoY Sales',
        description: 'Compare sales to last year to discover what drives growth',
        iconName: 'YoYSales',
        target: {
          reportName: 'Performance Insights',
          pageDisplayName: 'Supplier Trends',
        },
      },
      {
        title: 'Distribution Center Inventory',
        description: 'Monitor on-hand DC inventory to proactively replenish supply',
        iconName: 'DistributionInventory',
        target: {
          reportName: 'Inventory Flow Analysis',
          pageDisplayName: 'DC Details',
        },
      },
      {
        title: 'Distribution expansion',
        description: 'Find top-performing stores in your category to optimize your distribution',
        iconName: 'DistributionExpansion',
        target: {
          reportName: 'Distribution Analysis',
          pageDisplayName: 'Expansion Summary',
        },
      },
    ],
    termsAndConditionsUrl: 'https://www.dotfoods.com/Crisp-at-Dot/',
  },
};

const DefaultSettings: Settings = PartnerSettings[PartnerApplication.Dot];

export default PartnerSettings;

export const getPartnerSettings = (partnerApplication?: PartnerApplication) => {
  return (partnerApplication && PartnerSettings[partnerApplication]) || DefaultSettings;
};

export const usePartnerSettings = () => {
  const partnerApplication = usePartnerApplication();
  return useMemo(() => getPartnerSettings(partnerApplication), [partnerApplication]);
};
