import React, { FC } from 'react';
import { ReactComponent as IconConnect } from '../../../../assets/icons/lettuce/navrail-connectors.svg';
import { ReactComponent as IconAddAccount } from '../../../../assets/icons/lettuce/plus-circle.svg';
import { ReactComponent as Logo } from '../../../../assets/crisp-logo.svg';
import { FCC } from '../../../../utils/types';
import Headline from '../../components/Headline';
import Button, { ButtonVariant } from '../../components/Button';
import CardButton from '../../components/CardButton';
import PageTemplate from '../../crisp/navigation/PageTemplate';
import Panel from '../../components/Panel';
import MaxWidthLayout from '../../components/layout/MaxWidthLayout';
import style from './UserLinking.module.css';

export type UserLinkingProps = {
  onBookDemo: () => void;
  onActivateYourAccount: () => void;
  onLinkYourAccount: () => void;
  partnerName: string;
};
const LinkAccountButton: FCC<
  { partnerName: string } & Pick<
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    'onClick' | 'className' | 'disabled'
  >
> = ({ partnerName, ...rest }) => {
  const handleButtonClick = () => {};
  return (
    <CardButton
      label={'Link an existing Crisp Account'}
      description={`Already have Crisp? Link your account to view retail data in one place and export ${partnerName} data into your existing tools.`}
      onClick={handleButtonClick}
      Icon={IconConnect}
      {...rest}
    />
  );
};

const RequestAccountButton: FC<
  { partnerName: string } & Pick<
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    'onClick' | 'className' | 'disabled'
  >
> = ({ partnerName, ...rest }) => {
  const handleButtonClick = () => {};
  return (
    <CardButton
      label={'Activate your Crisp account'}
      description={`Included with your ${partnerName}, you can use Crisp to export data into your tool of choice and re-name or group products and stores.`}
      onClick={handleButtonClick}
      Icon={IconAddAccount}
      {...rest}
    />
  );
};

export const UserLinking: FC<UserLinkingProps> = ({
  onBookDemo,
  onActivateYourAccount,
  onLinkYourAccount,
  partnerName,
}) => {
  return (
    <>
      <div className={'ptm'}>
        <Headline size="M">Make the most of {partnerName} with Crisp</Headline>
      </div>
      <div className={'pbm phl'}>
        <RequestAccountButton
          className={style.primaryActionButton}
          onClick={onActivateYourAccount}
          partnerName={partnerName}
        />
        <LinkAccountButton
          className={style.primaryActionButton}
          onClick={onLinkYourAccount}
          partnerName={partnerName}
        />
      </div>
      <div>
        <div className={style.panelFooter}>
          <Headline as="h2" size="S" className={style.headline}>
            Get all your retail data, all in one place
          </Headline>
          <p>
            With Crisp, you can access your data from 40+ retailer and distributor partners,
            including UNFI, Whole Foods, Target, Walmart, and Amazon - all in one convenient
            location.
          </p>
          <Button variant={ButtonVariant.Secondary} onClick={onBookDemo}>
            Book a Demo
          </Button>
        </div>
      </div>
    </>
  );
};

const LandingPageTemplate: FCC = ({ children }) => {
  return (
    <PageTemplate>
      <div className={style.body}>
        <MaxWidthLayout>
          <div className={style.logo}>
            <Logo />
          </div>
          <Panel className={style.container}>{children}</Panel>
        </MaxWidthLayout>
      </div>
    </PageTemplate>
  );
};

export default LandingPageTemplate;
