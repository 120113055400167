import React from 'react';
import UserDetails from '../../components/lib/navigation/UserDetails';
import { usePartnerUser } from '../../partner/authentication/lib';

const PartnerUserDetails: React.FC = () => {
  const partnerUser = usePartnerUser();

  if (!partnerUser) {
    return null;
  }

  const { displayName, email } = partnerUser;
  if (!displayName || !email) {
    return null;
  }

  return <UserDetails displayName={displayName} email={email} />;
};

export default PartnerUserDetails;
