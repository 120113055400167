import { pascalCase } from 'change-case';
import { Maybe } from '@tellurian/ts-utils';
import _ from 'lodash';
import {
  ConnectorMetadataVersion,
  DocsFieldFragment,
  DocsTableFragment,
  MetadataModule,
  MetadataTableType,
} from '../../generated/graphql';
import { LocalStorage, LocalStorageKey } from '../../utils/localStorage';
import { capitalizeFirst } from '../../utils/capitalizeFirst';
import { withQueryParams } from '../../utils/querystringUtils';

/**
 * When a link to a table reference is set to open in a new tab, then the target property of the
 * anchor element should be set to the following.
 */
export const REFERENCE_LINK_TARGET = 'crisp_data_model';

export enum Mapping {
  Standard = 'Standard',
  OData = 'OData',
}

export const loadMapping = (): Maybe<Mapping> =>
  LocalStorage.getItem(LocalStorageKey.SelectedMapping);

export const saveMapping = (mapping: Mapping): boolean =>
  LocalStorage.setItem(LocalStorageKey.SelectedMapping, mapping);

export type Reference = {
  displayName: string;
  url: string;
};

export type TableReference = Reference & {
  tableType: MetadataTableType;
};

export type MetadataTableRenderContext = {
  isFieldEmphasized: (column: DocsFieldFragment, columnIndex: number) => boolean;
  getReferenceUrl: (tableId: string, columnName?: string) => string;
  referenceLinkTarget?: string;
};

export enum BadgeType {
  Source = 'source',
  Analysis = 'analysis',
  Fact = 'fact',
  Dimension = 'dimension',
  Harmonized = 'harmonized',
}

export const TableBadgeTypeMap: Readonly<Record<MetadataTableType, BadgeType>> = {
  SOURCE: BadgeType.Source,
  NORMALIZED_DIMENSION: BadgeType.Dimension,
  NORMALIZED_FACT: BadgeType.Fact,
  DENORMALIZED_FACT: BadgeType.Fact,
  ANALYSIS: BadgeType.Analysis,
  ANALYTICS: BadgeType.Analysis,
  UNIFIED: BadgeType.Harmonized,
  HARMONIZED: BadgeType.Harmonized,
  DIRECT: BadgeType.Source,
};

export type ModelVersion = Pick<ConnectorMetadataVersion, 'minorVersion' | 'majorVersion'>;

export type TableIdentifier = {
  moduleId: string; // This should probably be ConnectorApplication, however the API cannot pull
  // connectors by application yet (there might be a new future endpoint "modules" specifically for this)
  moduleVersion: ModelVersion;
  tableId: string;
};

export const versionEquals = (v1: ModelVersion, v2: ModelVersion): boolean =>
  v1.minorVersion === v2.minorVersion && v1.majorVersion === v2.majorVersion;

export const tableIdentifierEquals = (ti1: TableIdentifier, ti2: TableIdentifier): boolean => {
  return (
    ti1.moduleId === ti2.moduleId &&
    versionEquals(ti1.moduleVersion, ti2.moduleVersion) &&
    ti1.tableId === ti2.tableId
  );
};

export const versionDisplayValue = (version: ModelVersion): string =>
  [version.majorVersion, version.minorVersion].join('.');

type Serialization<T, P = undefined> = [
  serialize: (value: T) => string,
  deserialize: (value: string) => T | P,
];

export const TableIdentifierSerialization: Serialization<TableIdentifier> = [
  ({ tableId }) => tableId,
  value => {
    // FIXME: Do not want to do this, the ID should be opaque
    const parts = value.split('-');
    if (parts.length === 3) {
      const version = parts[1].split('.').map(item => parseInt(item, 10));
      return {
        moduleId: parts[0],
        moduleVersion: { majorVersion: version[0], minorVersion: version[1] },
        tableId: value, // Note, we inject the full ID here
      };
    }

    return undefined;
  },
];

// Values are used for sorting based on difference
export enum ColumnKeyType {
  PrimaryKey = 0,
  ForeignKey = 1,
  TimeKey = 2,
}

const ColumnKeyTypeCount = Object.keys(ColumnKeyType).length / 2;
const getColumnKeyTypeValue = (key: ColumnKeyType) => Math.pow(10, ColumnKeyTypeCount - key - 1);

export const isDescriptionIndicativeOfForeignKey = (description?: string): boolean =>
  description?.startsWith('Foreign key') || false;

export const isColumnForeignKeyByDescription = (column: DocsFieldFragment): boolean =>
  isDescriptionIndicativeOfForeignKey(column.description ?? undefined);
// This should ideally be exposed in the models.json in a similar manner to the PrimaryKey
export const isColumnForeignKey = (column: DocsFieldFragment): boolean =>
  isColumnForeignKeyByDescription(column) || !!column.reference;
// Added for consistency. Should be removed with the above when no longer necessary.
export const isColumnPrimaryKey = (column: DocsFieldFragment): boolean => column.isPrimaryKey;
export const isColumnTimeKey = (column: DocsFieldFragment): boolean => column.isTimeKey;

/**
 * The function returns a sort index for a column based on its key properties. The sort order
 * is defined as:
 * 1. Primary + Foreign + Time keys;
 * 2. Primary + Foreign keys;
 * 3. Primary + Time keys;
 * 4. Foreign + Time keys;
 * 5. Foreign keys;
 * 6. Time keys;
 * 7. No keys;
 * Note: this is based on the indices in ColumnKeyType.
 * @param column
 */
export const getColumnIndex = (column: DocsFieldFragment): number => {
  let index = 0;
  if (isColumnPrimaryKey(column)) {
    index += getColumnKeyTypeValue(ColumnKeyType.PrimaryKey);
  }
  if (isColumnForeignKey(column)) {
    index += getColumnKeyTypeValue(ColumnKeyType.ForeignKey);
  }
  if (isColumnTimeKey(column)) {
    index += getColumnKeyTypeValue(ColumnKeyType.TimeKey);
  }

  return index;
};

const KnownNames: Readonly<Record<string, string>> = {
  bjs_wholesale: "BJ's Wholesale",
  gnc: 'GNC',
  crisp_one_api: 'Crisp One API',
  'Hy Vee': 'Hy-Vee',
  super_valu: 'SuperValu',
  'Whole Foods Market': 'Whole Foods',
};

export const adjustModuleDisplayName = (name: string): string => {
  const knownName = KnownNames[name];
  return knownName || name.split('_').map(capitalizeFirst).join(' ');
};

// This should be temporary
export const adjustTableDisplayName = _.memoize((name: string, moduleName?: string) => {
  const prefixes = [`direct_${moduleName}`, 'direct_connected_partners'];
  const startsWithPrefix = prefixes.find(prefix => name.startsWith(prefix));
  if (startsWithPrefix) {
    return name.replace(startsWithPrefix, '').split('_').map(capitalizeFirst).join(' ');
  }

  return name.replace(/^Dim /, '').replace(/^Fact /, '');
});

/**
 * Apply presentation related transformations on the table schema.
 * Presently this will:
 * 1. Use pascal case for type information in "Standard" mapping (e.g. "STRING" -> "String");
 * 2. Order columns alphabetically and grouped by (Primary, Foreign, No) keys.
 * (E.g. Primary key columns, Foreign Key columns, rest of columns)
 * @param table
 * @param module
 */
export const adjustMetadataTable = <T extends DocsTableFragment>(
  table: T,
  module?: Pick<MetadataModule, 'displayName'>,
): T => {
  return {
    ...table,
    displayName: adjustTableDisplayName(table.displayName, module?.displayName),
    fields: table.fields
      .map(c => ({ ...c, dataType: pascalCase(c.dataType) }))
      .sort((c1, c2) => {
        const [c1KeyType, c2KeyType] = [c1, c2].map(getColumnIndex);
        return c1KeyType === c2KeyType ? c1.name.localeCompare(c2.name) : c2KeyType - c1KeyType;
      }),
  };
};

export enum KeyboardShortcutGroups {
  EditMetadataDocumentation = 'Edit metadata documentation',
}

export const getUrlForIdentifier =
  (currentPathname: string) =>
  (tableIdentifier: TableIdentifier, columnToEmphasize?: string): string =>
    withQueryParams({
      key: tableIdentifier.tableId,
      col: columnToEmphasize,
    })(currentPathname);

export type DataBoundComponentState = {
  isLoading: boolean;
  isDataAvailable: boolean;
};
