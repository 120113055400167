import React, { useMemo, useState, useTransition } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { UserFragment } from '../../../../generated/graphql';
import useEventCallback from '../../utils/useEventCallback';
import useIsGlobalAdmin from '../../../../utils/useIsGlobalAdmin';
import UserItemList from './UserItemList';
import UserItem, { UserItemProps } from './UserItem';
import { getUserAccountRole, UserAccountRoleOption, UserRoleLabel } from './lib';
import useOnCopyEmail from './useOnCopyEmail';
import UserListHeader from './UserListHeader';

type UserListProps = {
  accountId: string;
  users: UserFragment[];
  emailMatchesCurrentUser: (email: string) => boolean;
  onDelete: (user: UserFragment) => void;
  onChangePermissions: (user: UserFragment) => void;
  onChangeRetailAnalyticsAccess: (user: UserFragment) => void;
  className?: string;
};

const UserList: React.FC<UserListProps> = ({
  users,
  accountId,
  emailMatchesCurrentUser,
  onDelete,
  onChangePermissions,
  onChangeRetailAnalyticsAccess,
  className,
}) => {
  const [query, setQuery] = useState('');
  const [selectedRole, setSelectedRole] = useState<Maybe<UserAccountRoleOption>>();
  const [, startTransition] = useTransition();

  const onQueryChange = useEventCallback((nextQuery: string) => {
    startTransition(() => setQuery(nextQuery));
  });

  const filteredUsers = useMemo(() => {
    let res = users;
    const queryToUse = query.trim().toLowerCase();
    if (queryToUse) {
      res = users.filter(user => {
        if (user.displayName && user.displayName.toLowerCase().includes(queryToUse)) {
          return true;
        }

        return user.email.toLowerCase().includes(queryToUse);
      });
    }

    if (selectedRole) {
      res = res.filter(user => {
        return getUserAccountRole(user.accountPermissions, accountId) === selectedRole;
      });
    }

    return res;
  }, [query, users, accountId, selectedRole]);

  const onCopyEmail = useOnCopyEmail();
  const isGlobalAdmin = useIsGlobalAdmin();
  const userItems: UserItemProps[] = useMemo(() => {
    return filteredUsers.map(user => ({
      ...user,
      isCurrentUser: emailMatchesCurrentUser(user.email),
      onDelete: () => onDelete(user),
      onChangePermissions: () => onChangePermissions(user),
      onChangeRetailAnalyticsAccess: () => onChangeRetailAnalyticsAccess(user),
      getUserAccountRole: () => getUserAccountRole(user.accountPermissions, accountId),
      onCopyEmail: () => onCopyEmail(user.email),
      isGlobalAdmin,
    }));
  }, [
    isGlobalAdmin,
    onDelete,
    emailMatchesCurrentUser,
    filteredUsers,
    onChangePermissions,
    onChangeRetailAnalyticsAccess,
    accountId,
    onCopyEmail,
  ]);

  const noItems = userItems.length === 0 && (
    <p>
      No {selectedRole ? UserRoleLabel[selectedRole].toLowerCase() + 's' : 'users'}
      {query ? (
        <>
          {' '}
          matching <strong>{query}</strong>
        </>
      ) : (
        ''
      )}
      .
    </p>
  );

  return (
    <UserItemList<UserItemProps>
      header={
        <UserListHeader
          query={query}
          onQueryChange={onQueryChange}
          selectedRole={selectedRole}
          onSelectedRoleChange={setSelectedRole}
          autoFocusSearch={userItems.length > 10}
        />
      }
      items={userItems}
      RenderItem={UserItem}
      className={className}
      noItems={noItems}
    />
  );
};

export default UserList;
