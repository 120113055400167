import React from 'react';
import { useAuthenticationContext } from '../../../common/authentication/AuthenticationContext';
import UserDetails from './UserDetails';

const FirebaseUserDetails: React.FC = () => {
  const { firebaseUser, loading } = useAuthenticationContext();

  if (loading || !firebaseUser) {
    return null;
  }

  const { displayName, email } = firebaseUser;
  if (!displayName || !email) {
    return null;
  }

  return <UserDetails displayName={displayName} email={email} />;
};

export default FirebaseUserDetails;
