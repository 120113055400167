import React, { SVGProps } from 'react';
import clsx from 'clsx';
import style from './index.module.css';
import itemStyle from '../lib/ActionItem.module.css'; // eslint-disable-line

type CardButtonProps = {
  label: string;
  description: string;
  Icon?: React.FunctionComponent<SVGProps<SVGSVGElement>>;
} & Pick<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'onClick' | 'className' | 'disabled'
>;

const CardButton: React.FC<CardButtonProps> = ({
  Icon,
  label,
  description,
  className,
  ...rest
}) => {
  return (
    <button className={clsx(itemStyle.item, style.button, className)} type="button" {...rest}>
      {Icon && <Icon />}
      <div className={itemStyle.label}>{label}</div>
      <div className={itemStyle.description}>{description}</div>
    </button>
  );
};

export default CardButton;
