import React from 'react';
import clsx from 'clsx';
import { useDefaultHashId } from '../../../lib';
import FieldDetails from '../lib/FieldDetails';
import CheckList, { CheckListProps } from '../lib/CheckList';
import Title from '../Title';
import style from '../lib/CheckboxOrRadio/CheckboxOrRadioField.module.css'; // eslint-disable-line css-modules/no-unused-class

export type CheckboxFieldProps = Omit<CheckListProps, 'selection'> & {
  label?: React.ReactNode;
  description?: string;
  error?: string;
  value: CheckListProps['selection'];
};

const CheckListField = React.forwardRef<HTMLDivElement, CheckboxFieldProps>(function CheckListField(
  { className, id, label, error, description, value, ...checkListProps }: CheckboxFieldProps,
  ref,
) {
  id = useDefaultHashId(id);
  return (
    <div className={clsx('mod', className)}>
      {label && (
        <Title size="M" className="mhn">
          {label}
        </Title>
      )}
      <FieldDetails
        error={error}
        description={description}
        className={clsx('pan', 'mbs', {
          [style.hasError]: error,
        })}
      />
      <CheckList {...checkListProps} ref={ref} id={id} selection={value} />
    </div>
  );
});

export default CheckListField;
