import React, { useEffect, useRef, useState } from 'react';
import firebase from 'firebase/compat/app';
import { useHistory } from 'react-router-dom';
import Flex from '../../components/Flex';
import { Spinner, SpinnerSize } from '../../../ui';
import { inProgressLabel } from '../../../lib';
import Button, { ButtonVariant } from '../../components/Button';
import { usePartnerApplication } from '../PartnerApplicationProvider';
import { path } from '../routing/Routes';
import AuthPromptPanel from './AuthPromptPanel';
import usePartnerSignIn from './usePartnerSignIn';

const SignOut: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const partnerApplication = usePartnerApplication();
  const { signInThenGoTo } = usePartnerSignIn();

  const isLoggedOutRef = useRef(hasSearchParam('isLoggedOut'));

  useEffect(() => {
    if (isLoggedOutRef.current) {
      deleteSearchParam('isLoggedOut');
      history.push(path('PartnerHome')({ partnerApplication }));
    } else {
      firebase
        .auth()
        .signOut()
        .finally(() => {
          setLoading(() => false);
          addSearchParam('isLoggedOut', 'true');
        });
    }
  }, [partnerApplication, history]);

  return (
    <AuthPromptPanel>
      {loading && (
        <Flex size="S">
          <Spinner size={SpinnerSize.SMALL} />
          <div>{inProgressLabel('Logging out')}</div>
        </Flex>
      )}
      {!loading && (
        <>
          <div>You&apos;ve successfully logged out.</div>
          <Flex size="S">
            <Button variant={ButtonVariant.Secondary} onClick={() => signInThenGoTo('PartnerHome')}>
              Log back in
            </Button>
          </Flex>
        </>
      )}
    </AuthPromptPanel>
  );
};

const hasSearchParam = (searchParam: string) => {
  const params = new URLSearchParams(window.location.search);
  return params.has(searchParam);
};
const addSearchParam = (searchParam: string, value: string) => {
  const url = new URL(window.location.href);
  url.searchParams.set(searchParam, value);
  history.replaceState({}, '', url);
};
const deleteSearchParam = (searchParam: string) => {
  const url = new URL(window.location.href);
  url.searchParams.delete(searchParam);
  history.replaceState({}, '', url);
};

export default SignOut;
