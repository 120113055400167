import React, { useCallback } from 'react';
import { Route } from 'react-router';
import { RouteOptions } from '../../components/routing/lib';
import { path, Routes } from '../routing/Routes';
import NotFound from '../../components/routing/NotFound';
import RouteSwitch from '../../components/routing/RouteSwitch';
import { withQueryParams } from '../../../../utils/querystringUtils';
import { usePartnerApplication } from '../PartnerApplicationProvider';
import { usePartnerAuthenticationContext } from '../authentication/PartnerAuthenticationContext';

const NotFoundRoutes = (
  <>
    <Route exact path={'/p/*'} component={NotFound} />
  </>
);

const useGetSignInPath = () => {
  const partnerApplication = usePartnerApplication();
  return useCallback(
    (returnTo: string) =>
      withQueryParams({ returnTo })(path('PartnerSignIn')({ partnerApplication })),
    [partnerApplication],
  );
};

const DefaultRouteOptions: RouteOptions = { requireAuth: false };
const AppRouteSwitch: React.FC = () => (
  <RouteSwitch
    routes={Routes}
    defaultRouteOptions={DefaultRouteOptions}
    additionalRoutes={NotFoundRoutes}
    useIsSignedIn={usePartnerAuthenticationContext}
    getSignInPath={useGetSignInPath()}
  />
);

export default AppRouteSwitch;
