import React, { useEffect, useRef, useState } from 'react';
import { Maybe, noop } from '@tellurian/ts-utils';
import useEventCallback from '../../utils/useEventCallback';
import { FCC } from '../../../../utils/types';
import useKeyboardShortcut from '../../../keyboardSupport/useKeyboardShortcut';
import usePreferences from '../../common/preferences/usePreferences';
import UserPreferencesEditor, { UserPreferencesShortcutSpec } from './editor/UserPreferencesEditor';
import { PreferencesSectionId, useHasUserPreferenceOptions } from './editor/usePreferenceSections';

export const ThemeOptions = [
  'Crisp Lettuce',
  'UNFI',
  'Rema',
  'Advantage',
  'High Impact',
  'Dot',
  'Instacart',
] as const;
export type ThemeOption = (typeof ThemeOptions)[number];

type UserPreferences = {
  general: {
    theme: ThemeOption;
    overrideAccountTheme: boolean;
  };
  labs: {
    powerBi: {
      ux2: boolean;
      ux2ReportThemeEnabled: boolean;
      ux2HideSlicers: boolean;
      ux2EnableNativeFilterPanel: boolean;
      ux2EnableActionBar: boolean;
    };
  };
  powerBi: {
    navigation: {
      // Do not render menu items for reports with 0 pages and/or bookmarks
      hideReportsWithNoSubContent: boolean;
    };
  };
};

type PartialUserPreferences = Partial<{
  [K in keyof UserPreferences]: Partial<UserPreferences[K]>;
}>;

const DefaultPreferences: UserPreferences = {
  general: {
    theme: 'Crisp Lettuce',
    overrideAccountTheme: false,
  },
  labs: {
    powerBi: {
      ux2: false,
      ux2ReportThemeEnabled: true,
      ux2HideSlicers: true,
      ux2EnableNativeFilterPanel: false,
      ux2EnableActionBar: false,
    },
  },
  powerBi: {
    navigation: {
      hideReportsWithNoSubContent: true,
    },
  },
};

type UserPreferencesContextInterface = {
  preferences: UserPreferences;
  setPreferences: (preferences: UserPreferences) => void;
  updatePreferences: (preferences: PartialUserPreferences) => void;
  setTheme: (theme: ThemeOption) => void;
  toggleEditor: () => void;
  setPreferredSectionId: (sectionId: Maybe<PreferencesSectionId>) => void;
};

const UserPreferencesContext = React.createContext<UserPreferencesContextInterface>({
  preferences: DefaultPreferences,
  setPreferences: noop,
  updatePreferences: noop,
  setTheme: noop,
  toggleEditor: noop,
  setPreferredSectionId: noop,
});

const Versions = ['1'];
const getKey = () => `UserPreferences_v${Versions[0]}`;

const UserPreferencesContextProvider: FCC = ({ children }) => {
  const preferredSectionId = useRef<Maybe<PreferencesSectionId>>();
  const [isEditorActive, setIsEditorActive] = useState(false);
  const hasUserPreferenceOptions = useHasUserPreferenceOptions();
  const toggleEditor = useEventCallback(() => {
    if (hasUserPreferenceOptions) {
      setIsEditorActive(!isEditorActive);
    }
  });
  useKeyboardShortcut(UserPreferencesShortcutSpec, toggleEditor);
  const { setPreferences, updatePreferences, preferences } = usePreferences<UserPreferences>(
    getKey(),
    DefaultPreferences,
  );
  const setTheme = useEventCallback((theme: ThemeOption) => {
    updatePreferences({ general: { theme } });
  });

  const setPreferredSectionId = useEventCallback((sectionId: Maybe<PreferencesSectionId>) => {
    preferredSectionId.current = sectionId;
  });

  return (
    <UserPreferencesContext.Provider
      value={{
        preferences,
        setPreferences,
        updatePreferences,
        setTheme,
        toggleEditor,
        setPreferredSectionId,
      }}
    >
      <UserPreferencesEditor
        onClose={() => setIsEditorActive(false)}
        isActive={isEditorActive}
        initialSectionId={preferredSectionId.current}
      />
      {children}
    </UserPreferencesContext.Provider>
  );
};

export default UserPreferencesContextProvider;

export const useUserPreferencesContext = () => React.useContext(UserPreferencesContext);
export const usePreferredSectionId = (sectionId: PreferencesSectionId) => {
  const { setPreferredSectionId } = useUserPreferencesContext();
  useEffect(() => {
    setPreferredSectionId(sectionId);
    return () => setPreferredSectionId(undefined);
  }, [sectionId, setPreferredSectionId]);
};
