import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import { BrowserCacheLocation, Configuration, PublicClientApplication } from '@azure/msal-browser';
import { BrowserRouter } from 'react-router-dom';
import { NormalizedCacheObject } from '@apollo/client/cache';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { CookiesProvider } from 'react-cookie';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import SegmentTracker from './lettuce/common/tracking/SegmentTracker';
import withApolloClient from './withApolloClient';
import { FeatureFlagsProvider } from './lettuce/common/featureFlags/FeatureFlags';
import { AuthorizationProvider } from './security/AuthorizationContext';
import { PageErrorProvider } from './errorHandling/PageError/usePageError';
import { UnfiAuthenticationProvider } from './lettuce/unfiInsightsEmbedded/auth/UnfiAuthenticationProvider';
import { AuthenticationProvider } from './lettuce/common/authentication/AuthenticationContext';
import ThemeProvider from './lettuce/components/Theme/ThemeProvider';
import { Theme } from './lettuce/components/Theme';
import UnfiInsightsEmbeddedLogout from './lettuce/unfiInsightsEmbedded/auth/UnfiInsightsEmbeddedLogout';
import { resolveUnfiInsightsRedirectUri } from './lettuce/unfiInsightsEmbedded/lib';
import AppRouteSwitch from './lettuce/unfiInsightsEmbedded/routing/AppRouteSwitch';

const {
  REACT_APP_UNFI_INSIGHTS_CLIENT_ID,
  REACT_APP_UNFI_INSIGHTS_AUTHORITY,
  REACT_APP_UNFI_INSIGHTS_KNOWN_AUTHORITIES,
  REACT_APP_UNFI_INSIGHTS_PROVIDER_ID,
  REACT_APP_UNFI_INSIGHTS_SCOPES,
} = process.env;
const CLIENT_ID = REACT_APP_UNFI_INSIGHTS_CLIENT_ID || '';
const AUTHORITY = REACT_APP_UNFI_INSIGHTS_AUTHORITY || '';
const REDIRECT_URI = resolveUnfiInsightsRedirectUri();
const KNOWN_AUTHOROTIES = (REACT_APP_UNFI_INSIGHTS_KNOWN_AUTHORITIES || '').split(',');
const OIDC_PROVIDER_ID = REACT_APP_UNFI_INSIGHTS_PROVIDER_ID || '';
const SCOPES = (REACT_APP_UNFI_INSIGHTS_SCOPES || '').split(',');
export const LOGOUT_PATH = '/unfi-insights-embedded/logout';

const configuration: Configuration = {
  auth: {
    clientId: CLIENT_ID,
    authority: AUTHORITY,
    redirectUri: REDIRECT_URI,
    knownAuthorities: KNOWN_AUTHOROTIES,
  },
  system: {
    allowRedirectInIframe: true,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
  },
};
const pca = new PublicClientApplication(configuration);

type AppProps = {
  apolloClient: ApolloClient<NormalizedCacheObject>;
};

const App: React.FC<AppProps> = ({ apolloClient }) => {
  if (window.location.pathname === LOGOUT_PATH)
    return <UnfiInsightsEmbeddedLogout instance={pca} />;

  return (
    <div>
      <CookiesProvider>
        <AuthenticationProvider>
          <MsalProvider instance={pca}>
            <UnfiAuthenticationProvider providerId={OIDC_PROVIDER_ID} scopes={SCOPES}>
              <ApolloProvider client={apolloClient}>
                <PageErrorProvider>
                  <AuthorizationProvider>
                    <ThemeProvider theme={Theme.Unfi}>
                      <FeatureFlagsProvider>
                        <BrowserRouter>
                          <SegmentTracker />
                          <AppRouteSwitch />
                        </BrowserRouter>
                      </FeatureFlagsProvider>
                    </ThemeProvider>
                  </AuthorizationProvider>
                </PageErrorProvider>
              </ApolloProvider>
            </UnfiAuthenticationProvider>
          </MsalProvider>
        </AuthenticationProvider>
      </CookiesProvider>
    </div>
  );
};

export default withApolloClient(
  // @ts-expect-error Improper type required
  withLDProvider<AppProps>({
    clientSideID: '5d7970f8c6a4a007ae338556',
  })(App),
  false,
);
