import React, { useMemo, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { CookiesProvider } from 'react-cookie';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import SegmentTracker from '../common/tracking/SegmentTracker';
import { FeatureFlagsProvider } from '../common/featureFlags/FeatureFlags';
import { AuthorizationProvider } from '../../security/AuthorizationContext';
import { PageErrorProvider } from '../../errorHandling/PageError/usePageError';
import ThemeProvider from '../components/Theme/ThemeProvider';
import ConnectorLogoContextProvider from '../components/ConnectorLogo/ConnectorLogoProvider';
import initApollo from '../../../services/initApollo';
import PersistentUIStateContextProvider from '../components/PersistentUIState';
import useDocumentTitle from '../common/useDocumentTitle';
import NotFound from '../components/routing/NotFound';
import AppRouteSwitch from './routing/AppRouteSwitch';
import { PreferencesConsoleEditor, UserPreferencesProvider } from './preferences/UserPreferences';
import { getPartnerSettings } from './PartnerSettings';
import { getPartnerApplicationFromPathname } from './routing/Routes';
import PartnerAuthenticationProvider from './authentication/PartnerAuthenticationContext';
import PartnerApplicationProvider from './PartnerApplicationProvider';

const App: React.FC = () => {
  const [apolloClient] = useState(initApollo({}));
  const partnerApplication = useMemo(
    () => getPartnerApplicationFromPathname(window.location.pathname),
    [],
  );

  const { theme, documentTitle } = getPartnerSettings(partnerApplication);
  useDocumentTitle(documentTitle || 'Crisp');

  if (!partnerApplication) {
    return <NotFound />;
  }

  return (
    <div>
      <CookiesProvider>
        <PartnerApplicationProvider partnerApplication={partnerApplication}>
          <PartnerAuthenticationProvider>
            <UserPreferencesProvider>
              <PreferencesConsoleEditor />
              <PersistentUIStateContextProvider>
                <ApolloProvider client={apolloClient}>
                  <PageErrorProvider>
                    <AuthorizationProvider>
                      <ThemeProvider theme={theme}>
                        <FeatureFlagsProvider>
                          <ConnectorLogoContextProvider>
                            <BrowserRouter>
                              <SegmentTracker useEmailAsUserId={true} />
                              <AppRouteSwitch />
                            </BrowserRouter>
                          </ConnectorLogoContextProvider>
                        </FeatureFlagsProvider>
                      </ThemeProvider>
                    </AuthorizationProvider>
                  </PageErrorProvider>
                </ApolloProvider>
              </PersistentUIStateContextProvider>
            </UserPreferencesProvider>
          </PartnerAuthenticationProvider>
        </PartnerApplicationProvider>
      </CookiesProvider>
    </div>
  );
};

export default withLDProvider({
  clientSideID: '5d7970f8c6a4a007ae338556',
})(App);
