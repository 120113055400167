import { useMemo } from 'react';
import { usePartnerAuthenticationContext } from './PartnerAuthenticationContext';

export const usePartnerUser = () => {
  const { authConfiguration, firebaseUser } = usePartnerAuthenticationContext();
  return useMemo(
    () =>
      firebaseUser?.providerData.find(
        provider => provider?.providerId === authConfiguration.providerId,
      ),
    [firebaseUser, authConfiguration],
  );
};
