import React from 'react';
import { FormElement, PartialFormElementProps } from '../../../ui/form/FormFields';
import { CheckListProps } from '../lib/CheckList';
import CheckListField, { CheckboxFieldProps } from '../CheckListField';

type CheckListFormFieldProps = PartialFormElementProps<string[]> &
  Omit<CheckboxFieldProps, 'value' | 'onChange'> &
  // onChange is optional here
  Partial<Pick<CheckListProps, 'onChange'>>;

const CheckListFormField: React.FC<CheckListFormFieldProps> = ({
  id,
  validate,
  name,
  defaultValue,
  ...selectProps
}) => {
  return (
    <FormElement
      id={id}
      name={name}
      defaultValue={defaultValue}
      validate={validate}
      render={({ triggerValidation: _, errorMessage, onChange, ...renderProps }) => (
        <CheckListField
          {...renderProps}
          {...selectProps}
          error={errorMessage}
          onChange={nextValue => {
            onChange(nextValue);
            selectProps.onChange?.(nextValue);
          }}
        />
      )}
    />
  );
};

export default CheckListFormField;
