import React, { useState } from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { CookiesProvider } from 'react-cookie';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { PageErrorProvider } from '../../errorHandling/PageError/usePageError';
import { AuthorizationProvider } from '../../security/AuthorizationContext';
import ThemeProvider from '../components/Theme/ThemeProvider';
import { FeatureFlagsProvider } from '../common/featureFlags/FeatureFlags';
import ConnectorLogoContextProvider from '../components/ConnectorLogo/ConnectorLogoProvider';
import { AuthenticationProvider } from '../common/authentication/AuthenticationContext';
import SegmentTracker from '../common/tracking/SegmentTracker';
import initApollo from '../../../services/initApollo';
import PersistentUIStateContextProvider from '../components/PersistentUIState';
import useDocumentTitle from '../common/useDocumentTitle';
import { PreferencesConsoleEditor, UserPreferencesProvider } from './preferences/UserPreferences';
import AppRouteSwitch from './routing/AppRouteSwitch';
import { usePartnerSettings } from './PartnerSettings';

const App: React.FC = () => {
  const [apolloClient] = useState(initApollo({}));
  const { theme, documentTitle } = usePartnerSettings();
  useDocumentTitle(documentTitle);

  return (
    <div>
      <CookiesProvider>
        <AuthenticationProvider>
          <UserPreferencesProvider>
            <PreferencesConsoleEditor />
            <PersistentUIStateContextProvider>
              <ApolloProvider client={apolloClient}>
                <PageErrorProvider>
                  <AuthorizationProvider>
                    <ThemeProvider theme={theme}>
                      <FeatureFlagsProvider>
                        <ConnectorLogoContextProvider>
                          <BrowserRouter>
                            <SegmentTracker useEmailAsUserId={true} />
                            <AppRouteSwitch />
                          </BrowserRouter>
                        </ConnectorLogoContextProvider>
                      </FeatureFlagsProvider>
                    </ThemeProvider>
                  </AuthorizationProvider>
                </PageErrorProvider>
              </ApolloProvider>
            </PersistentUIStateContextProvider>
          </UserPreferencesProvider>
        </AuthenticationProvider>
      </CookiesProvider>
    </div>
  );
};

export default withLDProvider({
  clientSideID: '5d7970f8c6a4a007ae338556',
})(App);
