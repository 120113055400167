import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import clsx from 'clsx';
import {
  Maybe,
  useUnfiInsightsOnPlatformAccessRequestMutation,
} from '../../../../generated/graphql';
import { requiredField, email } from '../../../ui/form/validation';
import Headline from '../../components/Headline';
import Button, { ButtonVariant } from '../../components/Button';
import TextFormField from '../../components/formFields/TextFormField';
import { unfiEmbeddedPath } from '../../unfiInsightsEmbedded/routing/Routes';
import Spinner from '../../components/Spinner';
import { path } from '../../crisp/routing/lib';
import CheckBoxField from '../../components/CheckboxField';
import { Feature, trackFeature } from '../../../../utils/features';
import { useUnfiInsightsOnPlatformAuthenticationContext } from '../../unfiInsightsOnPlatform/UnfiInsightsOnPlatformAuthenticationProvider';
import HorizontalSeparator from '../../components/HorizontalSeparator';
import Title from '../../components/Title';
import CheckboxFormField from '../../components/formFields/CheckboxFormField';
import style from './RequestAccount.module.css';

const TOS_URL = process.env.REACT_APP_GOCRISP_TOS_URL;
const DATA_POLICY_URL = process.env.REACT_APP_GOCRISP_DATA_POLICY_URL;

export const PendingOnPlatformAccess: React.FC = () => {
  return (
    <div className={style.panel}>
      <Headline>Request Submitted</Headline>
      <p>Once approved, you will receive an email to finalize your account.</p>
      <div className={'pam'}>
        <Button variant={ButtonVariant.Primary} onClick={() => window.close()}>
          Close Window
        </Button>
      </div>
    </div>
  );
};

interface RequestAccountFeatureOptionssProps {
  featureNames: Maybe<string[]>;
}

type RequestAccountFeature = {
  name: string;
  label: string;
};

// array of all available account feautres
const allFeatureOptions: RequestAccountFeature[] = [
  {
    name: 'SharingReports',
    label: 'Sharing reports',
  },
  {
    name: 'RelabelingProducts',
    label: 'Relabeling products',
  },
  {
    name: 'CategorizingProducts',
    label: 'Categorizing products into filterable groups',
  },
  {
    name: 'ExportingData',
    label: 'Exporting data to an outbound destination',
  },
];

/**
 * A component that displays a list of check boxes representing either a subset of `allFeatures` above
 * or all available features if the features prop is null
 *
 * @param {string[]} featureNames - An array of `RequestAccountFeature` names, representing the subset of account feature options to display.
 * @returns {JSX.Element} The account features form element.
 */
const RequestAccountFeatureOptions: React.FC<RequestAccountFeatureOptionssProps> = ({
  featureNames,
}: RequestAccountFeatureOptionssProps): JSX.Element => {
  let selectedFeatures: RequestAccountFeature[];
  if (!featureNames) {
    selectedFeatures = allFeatureOptions;
  } else {
    selectedFeatures = allFeatureOptions.filter(feature => featureNames.includes(feature.name));
  }

  // generate the list of account feautre/option check boxes
  const accountOptions = selectedFeatures.map(feature => (
    <CheckboxFormField label={feature.label} name={feature.name} key={feature.name} />
  ));

  return (
    <>
      <Title size={'M'}>Which features are you going to use in Crisp?</Title>
      {accountOptions}
      <HorizontalSeparator />
    </>
  );
};

export const RequestAccountPanel: React.FC<{ showDetail: Dispatch<SetStateAction<boolean>> }> = ({
  showDetail,
}) => {
  const [submitRequest, { data: response, loading }] =
    useUnfiInsightsOnPlatformAccessRequestMutation();
  const { accessToken } = useUnfiInsightsOnPlatformAuthenticationContext();
  const [agreement, setAgreement] = useState(false);
  const [showAgreementError, setShowAgreementError] = useState(false);

  const useFormMethods = useForm({ shouldUnregister: false });
  const tosLink = (
    <a href={TOS_URL} target="_blank" rel="noreferrer">
      Terms of Service
    </a>
  );
  const dataPolicyLink = (
    <a href={DATA_POLICY_URL} target="_blank" rel="noreferrer">
      Data Policy
    </a>
  );

  const onSubmit = useFormMethods.handleSubmit(values => {
    if (!agreement) {
      setShowAgreementError(true);
      return;
    }
    trackFeature(Feature.UnfiInsightsAccountRequestSubmitted, { email: values.email });
    submitRequest({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
        company: values.company,
        email: values.email,
      },
    });
  });

  useEffect(() => {
    agreement && setShowAgreementError(false);
  }, [agreement]);

  useEffect(() => {
    if (!accessToken) {
      window.location.replace(
        unfiEmbeddedPath('UnfiInsightsEmbeddedSignIn')() +
          '?redirectUri=' +
          path('AccountSelect')(),
      );
    }
  }, [accessToken]);

  if (response?.unfiInsightsOnPlatformAccessRequest?.success) {
    return <PendingOnPlatformAccess />;
  }

  if (loading) {
    return (
      <div className={clsx('pam', style.panel)}>
        <Headline>Request your account</Headline>
        <p>Your request is being processed</p>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={style.panel}>
      <Headline>Request your account</Headline>
      <p>Enter your email address and our support team will follow up.</p>
      <div className={'pts'}>
        <FormProvider {...useFormMethods}>
          <TextFormField
            name="firstName"
            label="First Name"
            validate={[requiredField]}
            autoFocus={true}
            type="text"
          />
          <TextFormField name="lastName" label="Last Name" validate={[requiredField]} type="text" />
          <TextFormField
            name="company"
            label="Company Name"
            validate={[requiredField]}
            type="text"
          />
          <TextFormField
            name="email"
            label="Email address"
            validate={[requiredField, email]}
            type="email"
          />

          {/* features = null indicates display all account features/options */}
          <RequestAccountFeatureOptions featureNames={null} />

          <CheckBoxField
            name="agreement"
            label={
              <>
                I agree to Crisp&apos;s {tosLink} &amp; {dataPolicyLink}
              </>
            }
            checked={agreement}
            onChange={() => setAgreement(!agreement)}
            error={showAgreementError ? 'You must agree to the terms and conditions' : undefined}
          />

          <div className={'ptm pbs'}>
            <Button
              variant={ButtonVariant.Primary}
              onClick={() => {
                onSubmit();
              }}
            >
              Send Request
            </Button>
          </div>
          <div className={'pam'}>
            <Button variant={ButtonVariant.Text} onClick={() => showDetail(false)}>
              Back to UNFI-Crisp Account Options
            </Button>
          </div>
        </FormProvider>
      </div>
    </div>
  );
};
