import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  useGetAzureGroupsForAccountIdQuery,
  useGetPowerBiAzureUserQuery,
  useProvisionAzureUserForPowerBiMutation,
  UserFragment,
} from '../../../../generated/graphql';
import Modal, { ModalProps } from '../../components/Modal';
import useEventCallback from '../../utils/useEventCallback';
import Title from '../../components/Title';
import CheckListFormField from '../../components/formFields/CheckListFormField';
import { CheckListItem } from '../../components/lib/CheckList';
import SaveCancelActionButtons from '../../components/ActionButtons/SaveCancelActionButtons';
import Spinner from '../../components/Spinner';

type FormValues = { groupIds: string[] };

type RetailAnalyticsAccessModalProps = Pick<ModalProps, 'onCloseClick'> & {
  accountId: string;
  user: UserFragment;
};

const RetailAnalyticsAccessModal: React.FC<RetailAnalyticsAccessModalProps> = ({
  onCloseClick,
  accountId,
  user,
}) => {
  const useFormMethods = useForm<FormValues>({
    defaultValues: {
      groupIds: [],
    },
  });

  const { loading: groupsLoading, data } = useGetAzureGroupsForAccountIdQuery({
    variables: { accountId },
  });
  const groupItems: CheckListItem[] = useMemo(
    () =>
      data?.RetailAnalytics.retailAnalyticsUserProvisioningConfiguration.groups.map(
        ({ displayName, id }) => ({
          name: displayName,
          value: id,
        }),
      ) || [],
    [data],
  );

  const { data: azureUserData, loading: azureUserLoading } = useGetPowerBiAzureUserQuery({
    variables: { platformUserId: user.id },
    fetchPolicy: 'network-only',
  });

  const { reset } = useFormMethods;

  const loading = groupsLoading || azureUserLoading;

  useEffect(() => {
    const selectedGroupIds = azureUserData?.powerBi.user?.groups.map(g => g.id);
    if (selectedGroupIds) {
      reset({ groupIds: selectedGroupIds });
    }
  }, [azureUserData, reset]);

  const [provisionUser, { loading: isSubmitting }] = useProvisionAzureUserForPowerBiMutation();

  const submit = useEventCallback((data: FormValues) => {
    provisionUser({
      variables: {
        platformUserId: user.id,
        securityGroupIds: data.groupIds,
        excludeSecurityGroupIds: groupItems
          .map(g => g.value)
          .filter(g => !data.groupIds.includes(g)),
      },
    }).finally(onCloseClick);
  });

  return (
    <Modal
      title="Retail Analytics access"
      subtitle="Specify which groups the user should be a member of."
      onCloseClick={onCloseClick}
      footer={
        <SaveCancelActionButtons
          onSave={useFormMethods.handleSubmit(submit)}
          onCancel={onCloseClick}
          isSaveDisabled={loading}
          isSaving={isSubmitting}
        />
      }
    >
      <Title size="M">{user.email}</Title>
      <FormProvider {...useFormMethods}>
        {loading ? <Spinner /> : <CheckListFormField name="groupIds" items={groupItems} />}
      </FormProvider>
    </Modal>
  );
};

export default RetailAnalyticsAccessModal;
