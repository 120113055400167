import React, { ReactNode } from 'react';
import MaxWidthLayout from '../../components/layout/MaxWidthLayout';

type AuthTemplateProps<T extends object> = {
  children?: ReactNode;
  Layout?: 'div' | React.FC<T>;
};

function AuthTemplate<T extends object>({
  children,
  Layout = MaxWidthLayout as React.FC<T>,
  ...props
}: AuthTemplateProps<T>) {
  const layoutProps = props as T;
  return (
    <main id="main">
      <Layout {...layoutProps}>{children}</Layout>
    </main>
  );
}

export default AuthTemplate;
