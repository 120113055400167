import React from 'react';
import { RouteComponentProps } from 'react-router';
import { parseQuery } from '../../../../utils/querystringUtils';
import { lazyWithRetry } from '../../../lib';
import SignIn from '../authentication/SignIn';
import AuthTemplate from '../templates/AuthTemplate';

const RenderPartnerSignIn = lazyWithRetry<typeof SignIn>(
  () => import(/* webpackChunkName: "partnerSignIn" */ '../authentication/SignIn'),
);

const SignInPage: React.FC<RouteComponentProps> = ({ location }) => {
  const { returnTo, redirectUri } = parseQuery(location.search);

  return (
    <AuthTemplate>
      <RenderPartnerSignIn
        returnToUri={returnTo?.toString()}
        returnToExternalUri={redirectUri?.toString()}
      />
    </AuthTemplate>
  );
};

export default SignInPage;
