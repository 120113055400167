import React, { ReactNode } from 'react';
import { FCC } from '../../../../../utils/types';
import Flex from '../../Flex';
import CheckboxIcon from '../CheckboxIcon';
import styles from './index.module.css';

const CheckItem: FCC<{
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  children: ReactNode;
  disabled?: boolean;
}> = ({ isChecked, onChange, children, disabled = false }) => {
  return (
    <button
      role="option"
      aria-selected={isChecked}
      onClick={() => onChange(!isChecked)}
      disabled={disabled}
      className={styles.checkItem}
    >
      <Flex size="S">
        <CheckboxIcon size="M" isChecked={isChecked} />
        {children}
      </Flex>
    </button>
  );
};

export type CheckListItem = {
  name?: string;
  value: string;
};

export type CheckListProps = {
  items: CheckListItem[];
  selection?: string[];
  onChange: (nextSelection: string[]) => void;
  className?: string;
  id?: string;
};

const CheckList = React.forwardRef<HTMLDivElement, CheckListProps>(function CheckList(
  { items, selection = [], onChange, className, id },
  ref,
) {
  return (
    <div ref={ref} className={className} id={id}>
      {items.map(({ name, value }) => (
        <div key={value}>
          <CheckItem
            isChecked={selection.includes(value)}
            onChange={isChecked => {
              if (isChecked) {
                onChange([...selection, value]);
              } else {
                onChange(selection.filter(item => item !== value));
              }
            }}
          >
            {name || value}
          </CheckItem>
        </div>
      ))}
    </div>
  );
});

export default CheckList;
