import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { NormalizedCacheObject } from '@apollo/client/cache';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { CookiesProvider } from 'react-cookie';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { SkeletonTheme } from 'react-loading-skeleton';
import { I18nProvider } from '../utils/I18nContext';
import SegmentTracker from './lettuce/common/tracking/SegmentTracker';
import withApolloClient from './withApolloClient';
import { FeatureFlagsProvider } from './lettuce/common/featureFlags/FeatureFlags';
import { AuthenticationProvider } from './lettuce/common/authentication/AuthenticationContext';
import { AuthorizationProvider } from './security/AuthorizationContext';
import { PageErrorProvider } from './errorHandling/PageError/usePageError';
import { GlobalSiteGuides } from './GlobalSiteGuides/GlobalSiteGuides';
import { ToastContextProvider } from './Toast/ToastContext';
import NotificationCardPanel from './Toast/NotificationCardPanel';
import { ZendeskWidgetContextProvider } from './zendeskWidget/Context';
import EnableAccountTouchLog from './AccountManager/EnableAccountTouchLog';
import GlobalAdminPermissionKeyboardToggle from './security/GlobalAdminPermissionKeyboardToggle';
import { KeyboardShortcutContextProvider } from './keyboardSupport/KeyboardShortcutContext';
import 'react-loading-skeleton/dist/skeleton.css';
import AdminConsole from './AdminConsole';
import { PersistentThemeProvider } from './lettuce/components/Theme/ThemeProvider';
import { BookmarksProvider } from './BookmarksContext';
import UnfiMsalProvider from './lettuce/unfiInsightsOnPlatform/UnfiMsalProvider';
import { UnfiInsightsOnPlatformAuthenticationProvider } from './lettuce/unfiInsightsOnPlatform/UnfiInsightsOnPlatformAuthenticationProvider';
import { MaybeVerifyUnfiInsightsOnPlatformAccess } from './lettuce/unfiInsightsOnPlatform/MaybeVerifyUnfiInsightsOnPlatformAccess';
import IdentifyDatadogUser from './IdentifyDatadogUser';
import ToastNotificationPanel from './lettuce/components/ToastNotificationPanel';
import { ToastNotificationContextProvider } from './lettuce/components/lib/notifications/ToastNotificationContext';
import PageErrorNotifications from './lettuce/components/PageErrorNotifications';
import PageErrorRedirect from './lettuce/components/PageErrorRedirect';
import ConnectorLogoContextProvider from './lettuce/components/ConnectorLogo/ConnectorLogoProvider';
import { HelpLinkContextProvider } from './lettuce/crisp/navigation/HelpLinkContextProvider';
import AppRouteSwitch from './lettuce/crisp/routing/AppRouteSwitch';
import UserPreferencesProvider from './lettuce/crisp/UserPreferences/UserPreferencesProvider';
import ApplyPreferredTheme from './lettuce/crisp/UserPreferences/ApplyPreferredTheme';
import PersistentUIStateContextProvider from './lettuce/components/PersistentUIState';

type AppProps = {
  apolloClient: ApolloClient<NormalizedCacheObject>;
};

const AtlassianStatusNotifications = () => {
  useEffect(() => {
    const script: HTMLScriptElement = document.createElement('script');
    script.src = 'https://status.gocrisp.com/embed/script.js';
    script.type = 'text/javascript';
    document.body.appendChild(script);
  }, []);
  return null;
};

const App: React.FC<AppProps> = ({ apolloClient }) => {
  return (
    <CookiesProvider>
      <GlobalSiteGuides>
        <ToastNotificationContextProvider>
          <PageErrorNotifications />
          <AuthenticationProvider>
            <KeyboardShortcutContextProvider>
              <IdentifyDatadogUser />
              <ApolloProvider client={apolloClient}>
                <PageErrorProvider>
                  <AuthorizationProvider>
                    <UserPreferencesProvider>
                      <PersistentThemeProvider>
                        <PersistentUIStateContextProvider>
                          <EnableAccountTouchLog />
                          <FeatureFlagsProvider>
                            <I18nProvider>
                              <BrowserRouter>
                                <ApplyPreferredTheme />
                                <UnfiMsalProvider>
                                  <UnfiInsightsOnPlatformAuthenticationProvider>
                                    <MaybeVerifyUnfiInsightsOnPlatformAccess>
                                      <SegmentTracker />
                                      <AtlassianStatusNotifications />
                                      <ZendeskWidgetContextProvider>
                                        <HelpLinkContextProvider>
                                          <ToastContextProvider>
                                            <SkeletonTheme>
                                              <GlobalAdminPermissionKeyboardToggle />
                                              <PageErrorRedirect>
                                                <BookmarksProvider>
                                                  <ConnectorLogoContextProvider>
                                                    <AppRouteSwitch />
                                                  </ConnectorLogoContextProvider>
                                                </BookmarksProvider>
                                              </PageErrorRedirect>
                                              <NotificationCardPanel />
                                              <ToastNotificationPanel />
                                              <AdminConsole />
                                            </SkeletonTheme>
                                          </ToastContextProvider>
                                        </HelpLinkContextProvider>
                                      </ZendeskWidgetContextProvider>
                                    </MaybeVerifyUnfiInsightsOnPlatformAccess>
                                  </UnfiInsightsOnPlatformAuthenticationProvider>
                                </UnfiMsalProvider>
                              </BrowserRouter>
                            </I18nProvider>
                          </FeatureFlagsProvider>
                        </PersistentUIStateContextProvider>
                      </PersistentThemeProvider>
                    </UserPreferencesProvider>
                  </AuthorizationProvider>
                </PageErrorProvider>
              </ApolloProvider>
            </KeyboardShortcutContextProvider>
          </AuthenticationProvider>
        </ToastNotificationContextProvider>
      </GlobalSiteGuides>
    </CookiesProvider>
  );
};

export default withApolloClient(
  process.env.REACT_APP_DISABLE_LAUNCH_DARKLY === 'true'
    ? App
    : // @ts-expect-error Improper type required by LDProvider
      withLDProvider<AppProps>({
        clientSideID: '5d7970f8c6a4a007ae338556',
      })(App),
);
