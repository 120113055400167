import firebase from 'firebase/compat';
import bowser from 'bowser';

export type FirebaseUser = firebase.User;

export const browserDoesNotSupportLoginWithRedirect = () => {
  const browserName = bowser.getParser(window.navigator.userAgent).getBrowserName();
  const unsupportedBrowsers = [
    'Firefox',
    'Safari',
    process.env.NODE_ENV === 'development' && 'Chrome',
    process.env.NODE_ENV === 'development' && 'Microsoft Edge',
  ].filter(Boolean) as string[];
  return unsupportedBrowsers.includes(browserName);
};
